import { IonSpinner } from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import { attioStyleString } from 'components/BasicButton'
import { Dropdown } from 'components/Dropdown'
import CheckBox from 'components/editable-table/CheckBox'
import { LineChart, Settings } from 'lucide-react'
import { CompanyAvatar, getCompanies, MassiveCompany } from 'pages/admin/AdminCompanySheetPage'
import CompanyStatChart2 from 'pages/company/massiveEnrichedCompany/CompanyStatChart2'
import CompanyStatChartTimeFooter, {
  useChartTimeFooter,
} from 'pages/company/massiveEnrichedCompany/CompanyStatChartTimeFooter'
import { useCompanySelectorContext } from 'pages/company/massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'
import {
  numberToAbbreviatedDollarString,
  numberToReadableDollarString,
  numberToReadableString,
} from 'plural-shared/utils'
import { useMemo, useState } from 'react'
import { useIsSmallScreen } from 'several/hooks/navigation'
import { CompanyStatType, useAvailableGraphDatasets, useCompanyGraphDatasets } from 'several/pages/CompanyPage'
import DropdownSearch from './DropdownSearch'

export default function AddableContextStatChart(props: { defaultType: CompanyStatType }) {
  let availableData = useAvailableGraphDatasets()
  let { selectedEnrichedCompanyData } = useCompanySelectorContext()
  let [comparableCompanies, setComparableCompanies] = useState<string[]>([])
  let additionalData = useCompanyGraphDatasets(comparableCompanies) || {}
  let companiesQuery = useQuery({
    queryKey: ['companies'],
    queryFn: getCompanies,
  })
  const highContrastColors = [
    '#FF0000',
    '#00FF00',
    '#0000FF',
    '#FFFF00',
    '#00FFFF',
    '#FF00FF',
    '#800000',
    '#808000',
    '#008000',
    '#800080',
    '#008080',
    '#000080',
    '#FF4500',
    '#2E8B57',
    '#4682B4',
    '#D2691E',
    '#9ACD32',
    '#6A5ACD',
    '#20B2AA',
    '#FF1493',
    '#1E90FF',
    '#32CD32',
    '#FFD700',
    '#DC143C',
    '#7FFF00',
    '#FF6347',
    '#00FA9A',
    '#4169E1',
    '#FF69B4',
    '#BA55D3',
    '#CD5C5C',
    '#4B0082',
    '#7CFC00',
    '#FF4500',
    '#DA70D6',
    '#98FB98',
    '#B22222',
    '#F4A460',
    '#DDA0DD',
    '#E9967A',
    '#9ACD32',
    '#8B0000',
    '#8A2BE2',
    '#5F9EA0',
    '#7FFF00',
    '#D2691E',
    '#6495ED',
    '#DC143C',
    '#FF4500',
    '#DA70D6',
    '#98FB98',
    '#B22222',
  ]

  const companies: MassiveCompany[] = companiesQuery.data ?? []
  let lines = useMemo(
    () => [
      {
        label: 'Share Price',
        data: availableData.sharePrice,
        stroke: '#000',
        tickFormatter: numberToReadableDollarString,
        type: 'sharePrice',
        partnerDataAccessor: (data: any) => data?.sharePrice,
      },
      {
        label: 'Valuation',
        data: availableData.valuation,
        stroke: highContrastColors[0],
        tickFormatter: numberToAbbreviatedDollarString,
        type: 'valuation',
        partnerDataAccessor: (data: any) => data?.valuation,
      },
      {
        label: 'Open Roles',
        data: availableData.openRoles,
        stroke: highContrastColors[1],
        tickFormatter: numberToReadableString,
        type: 'openRoles',
        partnerDataAccessor: (data: any) => data?.openRoles,
      },
      {
        label: 'Headcount',
        data: availableData.headcount,
        stroke: highContrastColors[2],
        tickFormatter: numberToReadableString,
        type: 'headcount',
        partnerDataAccessor: (data: any) => data?.headcount,
      },
    ],
    [availableData],
  )

  let [selectedLines, setSelectedLines] = useState<CompanyStatType[]>([props.defaultType])

  let [normalizeYAxis, setNormalizeYAxis] = useState(true)
  let [asAPercentage, setAsAPercentage] = useState(false)
  let [selectedTime, setSelectedTime] = useChartTimeFooter()
  let defaultLine = lines.find((line) => line.type === props.defaultType)
  // @ts-ignore
  let scatterDataLines: {
    data: { x: Date; y: number }[]
    label: string
    stroke?: string
    fill?: string
  }[] = [
    // @ts-ignore
    ...lines
      .sort((a, b) => {
        return a.type === props.defaultType ? -1 : b.type === props.defaultType ? 1 : 0
      })
      .filter((line) => selectedLines.includes(line.type as CompanyStatType))
      .map((line) => {
        return line.type === props.defaultType && comparableCompanies.length > 0
          ? { ...line, label: selectedEnrichedCompanyData?.value?.companyName ?? 'base' }
          : line.type === props.defaultType
            ? line
            : {
                ...line,
                onClickLabel: (label: string) => {
                  setSelectedLines(selectedLines.filter((selectedLine) => selectedLine !== line.type))
                },
              }
      }),
    ...(comparableCompanies.length > 0
      ? comparableCompanies.map((companyId) => ({
          ...defaultLine,
          //@ts-ignore
          data: defaultLine?.partnerDataAccessor(additionalData[companyId]) || [],
          label: additionalData[companyId]?.companyName as string,
          stroke:
            highContrastColors[
              highContrastColors.length - ((comparableCompanies.indexOf(companyId) + 1) % highContrastColors.length)
            ],
          onClickLabel: (label: string) => {
            let companyId = Object.entries(additionalData).find(
              ([companyId, company]) => company.companyName === label,
            )?.[0]
            if (companyId) {
              setComparableCompanies(comparableCompanies.filter((id) => id !== companyId))
            }
          },
        }))
      : []),
  ]

  let isSmallScreen = useIsSmallScreen()
  return (
    <div>
      <div
        className="flex items-center justify-between rounded border px-2 py-3"
        style={{
          marginLeft: 30,
          marginRight: isSmallScreen ? 39.5 : 89.5,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderColor: 'rgb(231, 229, 228, 0.5)',
        }}>
        <div className="flex items-center gap-2">
          <Dropdown
            button={
              <div className={attioStyleString} style={{ width: 'fit-content' }}>
                <LineChart size={12} />
                Indicators
              </div>
            }>
            {lines
              .filter((line) => line.type !== props.defaultType)
              .map((line) => (
                <div key={line.type}>
                  <div className="flex items-center gap-2">
                    <CheckBox
                      checked={selectedLines.includes(line.type as CompanyStatType)}
                      onCheckChange={(checked) => {
                        if (checked) {
                          setSelectedLines([...selectedLines, line.type as CompanyStatType])
                        } else {
                          setSelectedLines(selectedLines.filter((selectedLine) => selectedLine !== line.type))
                        }
                      }}
                    />
                    <div>{line.label}</div>
                  </div>
                </div>
              ))}
          </Dropdown>
          <DropdownSearch
            options={companies.map((company) => ({
              label: company.companyName,
              value: company.companyId,
              additionalSearchString: company.companyName.toLowerCase(),
              startNode: (
                <CompanyAvatar
                  logo={company.companyS3LogoUrl}
                  alt={company.companyName}
                  altLetter={company.companyName.toUpperCase()}
                  size={20}
                />
              ),
            }))}
            trigger={
              <div
                className={attioStyleString}
                style={{ width: 'fit-content', pointerEvents: companiesQuery.isLoading ? 'none' : 'auto' }}>
                {companiesQuery.isLoading ? (
                  <div className="flex w-full flex-col items-center justify-center">
                    <IonSpinner name="lines-sharp" className="h-5 w-5" />
                  </div>
                ) : (
                  <>
                    <LineChart size={12} />
                    Comparables{' '}
                  </>
                )}
              </div>
            }
            onSelectOption={(newOption, unfocus) => {
              setComparableCompanies([...comparableCompanies, newOption.value])
              unfocus()
            }}
            placeholder="Add companies"
          />
        </div>
        <Dropdown
          align="end"
          button={
            <div className={attioStyleString} style={{ width: 'fit-content' }}>
              <Settings size={18} />
            </div>
          }>
          <div className="flex flex-col gap-2 p-1">
            <div className="flex flex-row items-center justify-between">
              <div className="text-xs">Normalize Y-Axis</div>
              <CheckBox checked={normalizeYAxis} onCheckChange={setNormalizeYAxis} />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="text-xs">As &nbsp; ∆&nbsp; %</div>
              <CheckBox checked={asAPercentage} onCheckChange={setAsAPercentage} />
            </div>
          </div>
        </Dropdown>
      </div>
      <div className="flex flex-col">
        <CompanyStatChart2
          asAPercentage={asAPercentage}
          chartName="Company Stats"
          scatterData={scatterDataLines}
          untilToday
          timeAgo={selectedTime}
          normalizeYAxis={normalizeYAxis}
          rightLabel={scatterDataLines.length > 1}
          continueLine
          tickFormatter={(value) =>
            props.defaultType === 'headcount' || props.defaultType === 'openRoles'
              ? numberToReadableString(value)
              : numberToAbbreviatedDollarString(value)
          }
        />
        <CompanyStatChartTimeFooter setSelectedTime={setSelectedTime} selectedTime={selectedTime} />
      </div>
    </div>
  )
}
