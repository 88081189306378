import { Month } from "../dataStructures";
import { createMarketOpenDate } from "../utils";

export const DEAL_SENSITIVITY_INPUT_OPTIONS = {
  CAGRs: [
    -1, -0.9, -0.8, -0.7, -0.6, -0.5, -0.45, -0.4, -0.35, -0.3, -0.25, -0.2,
    -0.15, -0.1, -0.09, -0.08, -0.07, -0.06, -0.05, -0.04, -0.03, -0.02, 0,
    0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.15, 0.2, 0.25,
    0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2, 2.5,
  ],
  sensitivityCAGRs: [
    -0.5, -0.4, -0.3, -0.2, -0.1, -0.05, 0, 0.05, 0.1, 0.2, 0.3, 0.4, 0.5,
  ],
  yearsToLiquidity: [2, 3, 4, 5, 6, 7, 8, 9, 10],
  reserveRatios: [3, 4, 5, 6],
};
// const AUTH0_REDIRECT_URI = 'https://developer\.trymassive\.com'
export const AUTH0_AUDIENCE = "https://api.auth.withplural.com";
export const AUTH0_DOMAIN = "dev-rkpqwt1irog4kyqs.us.auth0.com";
export const AUTH0_CUSTOM_DOMAIN = "https://auth.withplural.com";
export const AUTH0_CLIENT_ID = "QlxqHJvbVjNfxW7SZqNIOouvvF4pmpXR";
export const FUND_ONE_START_DATE = createMarketOpenDate(Month.June, 10, 2023);
export const FUND_TWO_START_DATE = createMarketOpenDate(Month.August, 15, 2023);
export function getStartDateForFundId(fundId: number) {
  const idToDate: { [fundId: number]: Date } = {
    1: FUND_ONE_START_DATE,
    2: FUND_TWO_START_DATE,
    411: FUND_ONE_START_DATE,
  };
  return idToDate[fundId];
}
export const JULY_MARK_DATE = createMarketOpenDate(Month.July, 1, 2023);
export const AUGUST_MARK_DATE = createMarketOpenDate(Month.August, 1, 2023);
export const SEPTEMBER_MARK_DATE = createMarketOpenDate(
  Month.September,
  1,
  2023
);
export const OCTOBER_MARK_DATE = createMarketOpenDate(Month.October, 1, 2023);
export const NOVEMBER_MARK_DATE = createMarketOpenDate(Month.November, 1, 2023);
export const DECEMBER_MARK_DATE = createMarketOpenDate(Month.December, 1, 2023);
export const JANUARY_MARK_DATE = createMarketOpenDate(Month.January, 1, 2024);
export const FEBRUARY_MARK_DATE = createMarketOpenDate(Month.February, 1, 2024);
export const MARCH_MARK_DATE = createMarketOpenDate(Month.March, 1, 2024);
export const APRIL_MARK_DATE = createMarketOpenDate(Month.April, 1, 2024);
export const MAY_MARK_DATE = createMarketOpenDate(Month.May, 1, 2024);
export const JUNE_2024_MARK_DATE = createMarketOpenDate(Month.June, 1, 2024);
export const JULY_2024_MARK_DATE = createMarketOpenDate(Month.July, 1, 2024);
export const AUGUST_2024_MARK_DATE = createMarketOpenDate(Month.August, 1, 2024);
export const SEPTEMBER_2024_MARK_DATE = createMarketOpenDate(Month.September, 1, 2024);
// export const OCTOBER_2024_MARK_DATE = createMarketOpenDate(Month.October, 1, 2024);
export const LATEST_MARK_DATE = SEPTEMBER_2024_MARK_DATE;

export enum FundMarkDateEnum {
  JULY_MARK_DATE = "JULY_MARK_DATE",
  AUGUST_MARK_DATE = "AUGUST_MARK_DATE",
  SEPTEMBER_MARK_DATE = "SEPTEMBER_MARK_DATE",
  OCTOBER_MARK_DATE = "OCTOBER_MARK_DATE",
  NOVEMBER_MARK_DATE = "NOVEMBER_MARK_DATE",
  DECEMBER_MARK_DATE = "DECEMBER_MARK_DATE",
  JANUARY_MARK_DATE = "JANUARY_MARK_DATE",
  FEBRUARY_MARK_DATE = "FEBRUARY_MARK_DATE",
  MARCH_MARK_DATE = "MARCH_MARK_DATE",
  APRIL_MARK_DATE = "APRIL_MARK_DATE",
  MAY_MARK_DATE = "MAY_MARK_DATE",
  JUNE_2024_MARK_DATE = "JUNE_2024_MARK_DATE",
  JULY_2024_MARK_DATE = "JULY_2024_MARK_DATE",
  AUGUST_2024_MARK_DATE = "AUGUST_2024_MARK_DATE",
  SEPTEMBER_2024_MARK_DATE = "SEPTEMBER_2024_MARK_DATE",
  // OCTOBER_2024_MARK_DATE = "OCTOBER_2024_MARK_DATE",
  LATEST_MARK_DATE = "LATEST_MARK_DATE",
  TODAY = "TODAY",
}

export const FundMarkDate = {
  [FundMarkDateEnum.JULY_MARK_DATE]: JULY_MARK_DATE,
  [FundMarkDateEnum.AUGUST_MARK_DATE]: AUGUST_MARK_DATE,
  [FundMarkDateEnum.SEPTEMBER_MARK_DATE]: SEPTEMBER_MARK_DATE,
  [FundMarkDateEnum.OCTOBER_MARK_DATE]: OCTOBER_MARK_DATE,
  [FundMarkDateEnum.NOVEMBER_MARK_DATE]: NOVEMBER_MARK_DATE,
  [FundMarkDateEnum.DECEMBER_MARK_DATE]: DECEMBER_MARK_DATE,
  [FundMarkDateEnum.JANUARY_MARK_DATE]: JANUARY_MARK_DATE,
  [FundMarkDateEnum.FEBRUARY_MARK_DATE]: FEBRUARY_MARK_DATE,
  [FundMarkDateEnum.MARCH_MARK_DATE]: MARCH_MARK_DATE,
  [FundMarkDateEnum.APRIL_MARK_DATE]: APRIL_MARK_DATE,
  [FundMarkDateEnum.MAY_MARK_DATE]: MAY_MARK_DATE,
  [FundMarkDateEnum.JUNE_2024_MARK_DATE]: JUNE_2024_MARK_DATE,
  [FundMarkDateEnum.JULY_2024_MARK_DATE]: JULY_2024_MARK_DATE,
  [FundMarkDateEnum.AUGUST_2024_MARK_DATE]: AUGUST_2024_MARK_DATE,
  [FundMarkDateEnum.SEPTEMBER_2024_MARK_DATE]: SEPTEMBER_2024_MARK_DATE,
  // [FundMarkDateEnum.OCTOBER_2024_MARK_DATE]: OCTOBER_2024_MARK_DATE,
  [FundMarkDateEnum.LATEST_MARK_DATE]: LATEST_MARK_DATE,
  [FundMarkDateEnum.TODAY]: createMarketOpenDate(
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getFullYear()
  ),
};

export type FundMarkDateType = `${FundMarkDateEnum}`;
export const FundMarkDateValues: FundMarkDateType[] =
  Object.values(FundMarkDateEnum);

export const MASSIVE_DEV_URL =
  process.env.NODE_ENV === "production"
    ? "https://developer.trymassive.com"
    : "http://localhost:3000";
