import { DatePicker } from 'components/DatePicker';
import React, { useState } from 'react';
import { trpc } from 'utils/trpc';

interface SimulationProps {
    investmentId: number;
}

const SimulateOutcome: React.FC<SimulationProps> = ({ investmentId }) => {
    const [showSimulation, setShowSimulation] = useState(false);
    const [valuationAmount, setValuationAmount] = useState<number | ''>('');
    const [valuationUnit, setValuationUnit] = useState<'M' | 'B'>('B');
    const [exitDate, setExitDate] = useState<Date>(new Date());
    const [simulationResult, setSimulationResult] = useState<any>(null);

    const simulateMutation = trpc.fund.simulateOutcome.useMutation();

    const handleSimulate = async () => {
        if (valuationAmount === '') return;

        const valuationMultiplier = valuationUnit === 'M' ? 1_000_000 : 1_000_000_000;
        const valuation = Number(valuationAmount) * valuationMultiplier;

        const result = await simulateMutation.mutateAsync({
            journalEntryId: investmentId,
            valuation: valuation,
            exitDate: exitDate.toISOString()
        });

        setSimulationResult(result);
    };

    if (!showSimulation) {
        return (
            <button
                onClick={() => setShowSimulation(true)}
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
            >
                Simulate Exit
            </button>
        );
    }

    return (
        <div className="p-4 border rounded-lg shadow-sm">
            <h2 className="text-xl font-semibold mb-4">Simulate Exit</h2>
            <div className="space-y-4">
                <div className="flex items-center space-x-2">
                    <div className="flex-grow">
                        <label className="block text-sm font-medium text-gray-700">Valuation</label>
                        <input
                            type="number"
                            value={valuationAmount}
                            onChange={(e) => setValuationAmount(e.target.value ? Number(e.target.value) : '')}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            placeholder="e.g. 6.5"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Unit</label>
                        <select
                            value={valuationUnit}
                            onChange={(e) => setValuationUnit(e.target.value as 'M' | 'B')}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        >
                            <option value="M">Million</option>
                            <option value="B">Billion</option>
                        </select>
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Exit Date</label>
                    <DatePicker
                        asTextField
                        date={exitDate}
                        setDate={(newDate) => setExitDate(newDate ?? new Date())}
                        minDate={new Date()}
                    />
                </div>
                <button
                    onClick={handleSimulate}
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
                >
                    Simulate
                </button>
                {simulationResult && (
                    <div className="mt-6 bg-white shadow-md rounded-lg p-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="bg-gray-50 p-4 rounded-md">
                                <p className="text-sm font-medium text-gray-500">Shares owed</p>
                                <p className="text-2xl font-bold text-gray-900">{simulationResult.sharesOwed}</p>
                            </div>
                            <div className="bg-gray-50 p-4 rounded-md">
                                <p className="text-sm font-medium text-gray-500">Sale PPS to Plural</p>
                                <p className="text-2xl font-bold text-gray-900">${simulationResult.basePrice.toFixed(2)}</p>
                            </div>
                            <div className="bg-gray-50 p-4 rounded-md">
                                <p className="text-sm font-medium text-gray-500">Plural Interest (at current IRR)</p>
                                <p className="text-2xl font-bold text-gray-900">{simulationResult.investmentValue}</p>
                            </div>
                            <div className="bg-gray-50 p-4 rounded-md">
                                <p className="text-sm font-medium text-gray-500">Implied PPS at exit</p>
                                <p className="text-2xl font-bold text-gray-900">${simulationResult.sharePriceAtExit.toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SimulateOutcome;