import { safeCastToNumber } from 'pages/cashSimulator/CashSimulatorContext'
import { MASSIVE_DEV_URL } from 'plural-shared/constants'
import { MasterFundingRound, RawMasterFundingRound, UpdateMassiveFundingRoundData } from 'several/models/FundingRounds'

export function processFundingRound(fundingRound: RawMasterFundingRound): MasterFundingRound {
  return {
    ...fundingRound,
    impliedEnterpriseValue: safeCastToNumber(fundingRound.impliedEnterpriseValue),
    announcedTotal: safeCastToNumber(fundingRound.announcedTotal),
    announcedPostMoneyValuation: safeCastToNumber(fundingRound.announcedPostMoneyValuation),
    announcedImpliedEnterpriseValue: safeCastToNumber(fundingRound.announcedImpliedEnterpriseValue),
    closedTotal: safeCastToNumber(fundingRound.closedTotal),
    postMoneyValuation: safeCastToNumber(fundingRound.postMoneyValuation),
    subRows: fundingRound.subRows?.map((subRow) => processFundingRound(subRow)),
    sourceMap: fundingRound.sourceMap,
  }
}

function processPatchFundingRound(fundingRound: MasterFundingRound): RawMasterFundingRound {
  function parseBigInt(value: number | undefined) {
    return value === null ? null : value?.toString()
  }
  return {
    ...fundingRound,
    impliedEnterpriseValue: parseBigInt(fundingRound.impliedEnterpriseValue),
    announcedTotal: parseBigInt(fundingRound.announcedTotal),
    announcedPostMoneyValuation: parseBigInt(fundingRound.announcedPostMoneyValuation),
    announcedImpliedEnterpriseValue: parseBigInt(fundingRound.announcedImpliedEnterpriseValue),
    closedTotal: parseBigInt(fundingRound.closedTotal),
    postMoneyValuation: parseBigInt(fundingRound.postMoneyValuation),
  } as unknown as RawMasterFundingRound
}
export function patchFundingRound(variables: { fundingRound: UpdateMassiveFundingRoundData }) {
  return async () => {
    const response = await fetch(`${MASSIVE_DEV_URL}/api/update-massive-funding-round-data`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer 84c3598f-ec4c-45a5-ab0e-31a291723029`,
      },
      body: JSON.stringify({
        ...processPatchFundingRound(variables.fundingRound as unknown as MasterFundingRound),
      }),
    })
    let res = (await response.json()) as RawMasterFundingRound
    return processFundingRound(res)
  }
}

export function getFundingRounds(
  companyId?: string,
  skipCache?: boolean,
  includeSourceMap?: boolean,
): () => Promise<MasterFundingRound[]> {
  // update nesting logic so it will nest by company when we can
  return async () => {
    const companyIds = companyId ? [companyId] : []
    const response = await fetch(
      `${MASSIVE_DEV_URL}/api/get-funding-rounds`,
      //   'https://massive-webapp-production.eba-mpymhj2x.us-east-1.elasticbeanstalk.com/api/get-funding-rounds',
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer 84c3598f-ec4c-45a5-ab0e-31a291723029`,
        },
        body: JSON.stringify({
          companyIds: companyIds,
          skipCache: skipCache,
          includeSourceMap: includeSourceMap,
        }),
      },
    )
    let res = (await response.json()) as {
      cidToFundingRounds: {
        [key: string]: RawMasterFundingRound[]
      }
    }
    let cidToFundingRoundsMap = new Map<string, RawMasterFundingRound[]>()

    for (const [key, value] of Object.entries(res.cidToFundingRounds)) {
      cidToFundingRoundsMap.set(key, value)
    }
    let filteredRes = companyId
      ? cidToFundingRoundsMap.get(companyId) ?? []
      : Array.from(cidToFundingRoundsMap.values()).reduce((acc, val) => acc.concat(val), [])
    return filteredRes.map((round) => processFundingRound(round))
  }
}

export function mergeFundingRounds(
  fundingRoundAId: string,
  fundingRoundBId: string,
): () => Promise<MasterFundingRound> {
  // update nesting logic so it will nest by company when we can
  return async () => {
    const payload = {
      fundingRoundIdA: fundingRoundAId,
      fundingRoundIdB: fundingRoundBId,
    }
    try {
      const response = await fetch(`${MASSIVE_DEV_URL}/api/merge-funding-rounds`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer 84c3598f-ec4c-45a5-ab0e-31a291723029`,
        },
        body: JSON.stringify(payload),
      })
      if (!response.ok) {
        let errorMessage = ''
        try {
          const errorResponse = await response.json()
          if ('error' in errorResponse) {
            errorMessage = errorResponse.error
          } else if ('message' in errorResponse) {
            errorMessage = errorResponse.message
          } else {
            errorMessage = JSON.stringify(errorResponse)
          }
        } catch (e) { }

        throw new Error(`HTTP error! status: ${response.status}. ${errorMessage}`)
      }

      let res = (await response.json()) as RawMasterFundingRound

      return processFundingRound(res)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export function associateFundingRounds(
  parentFundingRoundId: string,
  childrenFundingRoundIds: string[],
): () => Promise<string> {
  // update nesting logic so it will nest by company when we can
  return async () => {
    const payload = {
      parentFundingRoundId: parentFundingRoundId,
      childrenFundingRoundIds: childrenFundingRoundIds,
    }
    const response = await fetch(`${MASSIVE_DEV_URL}/api/associate-funding-rounds`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer 84c3598f-ec4c-45a5-ab0e-31a291723029`,
      },
      body: JSON.stringify(payload),
    })
    let res = (await response.json()) as string

    return res
  }
}

export function unmergeFundingRound(fundingRoundId: string): () => Promise<void> {
  return async () => {
    const payload = {
      fundingRoundId: fundingRoundId,
    }
    const response = await fetch(`${MASSIVE_DEV_URL}/api/unmerge-funding-round`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer 84c3598f-ec4c-45a5-ab0e-31a291723029`,
      },
      body: JSON.stringify(payload),
    })
    if (response.status !== 200) {
      throw new Error('Error unmerging funding round')
    }

    return Promise.resolve()
  }
}

export async function postFundingRound(companyId: string, email: string, companyName: string) {
  let payload = {
    companyId: companyId,
    adminEmail: email,
    companyName: companyName,
  }

  const response = await fetch(`${MASSIVE_DEV_URL}/api/initialize-funding-round`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer 84c3598f-ec4c-45a5-ab0e-31a291723029`,
    },
    body: JSON.stringify(payload),
  })
  let res = (await response.json()) as RawMasterFundingRound
  return processFundingRound(res)
}
