import {
  ArrowBottomRightIcon,
  ArrowTopRightIcon,
  CardStackPlusIcon,
  CaretDownIcon,
  CaretUpIcon,
  PlusIcon,
} from '@radix-ui/react-icons'
import { useQueries, useQueryClient } from '@tanstack/react-query'
import { getQueryKey } from '@trpc/react-query'
import { BasicButton } from 'components/BasicButton'
import SmallChart from 'components/charts/SmallChart'
import Tabs from 'components/Tabs'
import {
  BarChart4,
  BookOpen,
  CalendarIcon,
  CandlestickChartIcon,
  CoinsIcon,
  Factory,
  GemIcon,
  MapPin,
  ScaleIcon,
  Scroll,
  ScrollText,
  Users,
  UsersIcon,
  Zap,
} from 'lucide-react'
import { CompanyAvatar } from 'pages/admin/AdminCompanySheetPage'
import { safeCastToNumber } from 'pages/cashSimulator/CashSimulatorContext'
import CompanyMarks from 'pages/company/CompanyMarks'
import { CompanyRevenueTable } from 'pages/company/CompanyRevenueTable'
import { MarksChart } from 'pages/company/MarksChart'
import CompanyStatChart2 from 'pages/company/massiveEnrichedCompany/CompanyStatChart2'
import {
  useCompanySelectorContext,
  withCompanySelectorContext,
} from 'pages/company/massiveEnrichedCompany/MassiveEnrichedCompanySelectorContextProvider'
import {
  numberToAbbreviatedDollarString,
  numberToAbbreviatedString,
  numberToReadableDollarString,
} from 'plural-shared/utils'
import { PluralLink } from 'providers/NavigationProvider'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useVanillaTRPC } from 'providers/TRPCProvider'
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import { isSome } from 'safety'
import AddableContextStatChart from 'several/components/AddableContextStatChart'
import CompanyFundingRoundsTable from 'several/components/CompanyFundingRoundsTable'
import DropdownSearch from 'several/components/DropdownSearch'
import { hackyEditorIds } from 'several/hooks/FundingRound'
import { useIsSmallScreen } from 'several/hooks/navigation'
import { trpc } from 'utils/trpc'

function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d?.getTime())
}

export function useAvailableGraphDatasets(): {
  [key: string]: { x: Date; y: number }[]
} {
  const { selectedEnrichedCompanyData } = useCompanySelectorContext()
  let fundingData = (selectedEnrichedCompanyData.value?.fundingRounds ?? []).sort(
    (a: any, b: any) =>
      new Date(a.closedDate || a.announcedDate || '').getTime() -
      new Date(b.closedDate || b.announcedDate || '').getTime(),
  )

  let fundingDataGraph = fundingData
    .map((data: any) => ({
      x: new Date(data.closedDate || data.announcedDate),
      y: safeCastToNumber(data.pricePerShare),
    }))
    .filter((val: any) => isValidDate(val.x) && val.y)

  let valuationDataGraph = fundingData
    .map((data: any) => ({
      x: new Date(data.closedDate || data.announcedDate),
      y: safeCastToNumber(data.postMoneyValuation || data.announcedPostMoneyValuation),
    }))
    .filter((val: any) => isValidDate(val.x) && val.y)

  let financingsDataGraph = fundingData
    .map((data: any) => ({
      x: new Date(data.closedDate || data.announcedDate),
      y: safeCastToNumber(data.closedTotal || data.announcedTotal),
    }))
    .filter((val: any) => isValidDate(val.x) && val.y)

  let { totalAdjustedHeadcountData } = useHeadcountChartData()
  let openRoleData = useOpenRoleChartData()

  return {
    sharePrice: fundingDataGraph,
    valuation: valuationDataGraph,
    financingsDataGraph,
    headcount: totalAdjustedHeadcountData,
    openRoles: openRoleData,
  }
}

export function useCompanyGraphDatasets(companyIds: string[]): {
  [companyId: string]: {
    [key: string]: { x: Date; y: number }[] | string
  }
} {
  const vanillaClient = useVanillaTRPC()
  const queries = useQueries({
    queries: companyIds.map((companyId) => {
      return {
        queryKey: ['getEnrichedCompanyData', companyId],
        queryFn: () => vanillaClient.companies.getEnrichedCompanyData.query(companyId),
        enabled: !!companyId,
      }
    }),
  })

  return queries.reduce((acc, query) => {
    if (query.data) {
      let selectedEnrichedCompanyData = query.data?.companyData!
      let fundingData = (selectedEnrichedCompanyData.fundingRounds ?? []).sort((a: any, b: any) => {
        return (
          new Date(a.closedDate || a.announcedDate || '').getTime() -
          new Date(b.closedDate || b.announcedDate || '').getTime()
        )
      })
      return {
        ...acc,
        [selectedEnrichedCompanyData.companyId]: {
          sharePrice: fundingData
            .map((data: any) => ({
              x: new Date(data.closedDate || data.announcedDate),
              y: safeCastToNumber(data.pricePerShare),
            }))
            .filter((val: any) => isValidDate(val.x) && val.y),
          valuation: fundingData
            .map((data: any) => ({
              x: new Date(data.closedDate || data.announcedDate),
              y: safeCastToNumber(data.postMoneyValuation || data.announcedPostMoneyValuation),
            }))
            .filter((val: any) => isValidDate(val.x) && val.y),
          financingsDataGraph: fundingData
            .map((data: any) => ({
              x: new Date(data.closedDate || data.announcedDate),
              y: safeCastToNumber(data.closedTotal || data.announcedTotal),
            }))
            .filter((val: any) => isValidDate(val.x) && val.y),
          headcount: selectedEnrichedCompanyData.allHeadcountStats
            .map((data: any) => ({
              x: new Date(data.statsDate),
              y: data.adjustedCount,
            }))
            .filter((val: any) => isValidDate(val.x) && val.y),
          openRoles: selectedEnrichedCompanyData.allOpenRoleStats
            .map((data: any) => ({
              x: new Date(data.statsDate),
              y: data.adjustedCount,
            }))
            .filter((val: any) => isValidDate(val.x) && val.y),
          companyName: selectedEnrichedCompanyData.companyName,
        },
      }
    }
    return acc
  }, {} as any)
}

export default withCompanySelectorContext(CompanyPage, '/company')
function CompanyPage() {
  let [expandedTable, setExpandedTable] = useState(false)
  const { pluralCompanyId, selectedEnrichedCompanyData, companyId, isLoadingCompanies } = useCompanySelectorContext()
  let isPublic = selectedEnrichedCompanyData.value?.isPublic ?? false

  let { user } = usePluralAuth()
  let investors = selectedEnrichedCompanyData.value?.fundingRounds.flatMap((fr: any) => fr.investors) ?? []

  let investorsSet = new Set(investors.filter((investor: any) => investor).map((investor: any) => investor.name))
  let investorNames = Array.from(investorsSet) as string[]

  let addToListMutation = trpc.sheetRouter.addCompaniesToSheet.useMutation()

  let companySheetsToAdd: any[] =
    //   @ts-ignore
    user?.userToSheets
      .filter((utr: any) => utr.sheet.entityType === 'COMPANY' && (utr.role === 'OWNER' || utr.role === 'EDITOR'))
      .map((utr: any) => utr.sheet) ?? []
  let queryClient = useQueryClient()
  let isSmallScreen = useIsSmallScreen()
  let isMediumScreen = useIsSmallScreen('md')
  let isEditor = hackyEditorIds.includes(user?.id ?? 0)

  const openRoleGraphData = useOpenRoleChartData()
  let { totalHeadcountData: totalHeadcountGraphData } = useHeadcountChartData()

  let sheetsContainingCompanyQuery = trpc.sheetRouter.findSheetsContainingColumnFilter.useQuery(
    {
      field: 'companyName',
      value: selectedEnrichedCompanyData.value?.companyName ?? '',
    },
    {
      enabled: !!selectedEnrichedCompanyData.value?.companyName,
    },
  )

  let pluralSheetsContainingCompany = sheetsContainingCompanyQuery.data ?? []

  const tabs: {
    icon: ReactNode
    label: string
    value: string
    content: ReactNode
    showTab: boolean
    className?: string
  }[] = [
    {
      icon: <CandlestickChartIcon size={15} />,
      label: 'Share Price',
      value: '1',
      content: <AddableContextStatChart defaultType="sharePrice" />,
      // content: <SharePriceChart />,
      showTab:
        (selectedEnrichedCompanyData.value?.fundingRounds.filter((round: any) => {
          return round.pricePerShare
        }).length ?? 0) > 0,
    },
    {
      icon: <GemIcon size={15} />,
      label: 'Valuation',
      value: '2',
      content: <AddableContextStatChart defaultType="valuation" />,
      showTab:
        (selectedEnrichedCompanyData.value?.fundingRounds.filter(
          (round: any) => round.postMoneyValuation || round.announcedPostMoneyValuation,
        ).length ?? 0) > 0,
    },
    {
      icon: <CoinsIcon size={15} />,
      label: 'Financings',
      value: '3',
      content: <FinancingsChart />,
      showTab: (selectedEnrichedCompanyData.value?.fundingRounds.length ?? 0) > 0,
    },
    {
      icon: <UsersIcon size={15} />,
      label: 'Headcount',
      value: '4',
      // content: <HeadcountChart />,
      showTab: totalHeadcountGraphData.length > 0,
      content: <AddableContextStatChart defaultType="headcount" />,
    },
    {
      icon: <BookOpen size={15} />,
      label: 'Open Roles',
      value: '5',
      // content: <OpenRolesChart />,
      showTab: openRoleGraphData.length > 0,
      content: <AddableContextStatChart defaultType="openRoles" />,
    },
    {
      icon: <Factory size={15} />,
      label: 'Revenue',
      showTab: selectedEnrichedCompanyData.value != null,
      value: '6',
      content: <CompanyRevenueTable />,
      className: isEditor ? '' : `pointer-events-none cursor-not-allowed`,
    },
    {
      icon: <Zap size={15} />,
      label: 'Plural PPS',
      showTab: pluralCompanyId.value != null,
      value: '7',
      content: (
        <>
          <MarksChart variant="companyVariant" />
          {isEditor && <CompanyMarks />}
        </>
      ),
    },
  ]

  let [tabValue, setTabValue] = useState(tabs?.find((tab) => tab.showTab)?.value ?? '1')

  useEffect(() => {
    let firstActiveTab = tabs?.find((tab) => tab.showTab)?.value
    setTabValue(tabs?.find((tab) => tab.showTab)?.value ?? '1')
  }, [JSON.stringify(tabs.map((tab) => tab.showTab))])

  const { totalAdjustedHeadcountData } = useHeadcountChartData()
  let isHeadcountData = totalAdjustedHeadcountData.filter((data) => isSome(data.x) && isSome(data.y)).length > 0
  let isValuationData =
    selectedEnrichedCompanyData.value?.fundingRounds.filter(
      (round: any) => round.postMoneyValuation || round.announcedPostMoneyValuation,
    ).length > 0

  let isLoading = isLoadingCompanies && !selectedEnrichedCompanyData.value
  return (
    <div className="flex flex h-full w-full flex-row overflow-hidden" style={{ height: '100vh' }}>
      <div className="min-h-[75vh] overflow-auto">
        <div className="flex flex-row items-center justify-between border-b p-3">
          <div className="flex flex-row items-center gap-2">
            <CompanyAvatar
              size={40}
              logo={selectedEnrichedCompanyData.value?.companyS3LogoUrl ?? undefined}
              altLetter={selectedEnrichedCompanyData.value?.companyName[0] ?? '?'}
              alt={selectedEnrichedCompanyData.value?.companyName ?? 'unknown logo'}
            />
            <div className="flex flex-col justify-between">
              <div className="flex flex-row items-center gap-2 text-sm font-bold">
                {selectedEnrichedCompanyData.value?.companyName}{' '}
                {isPublic ? (
                  <div>
                    <div className="rounded-full bg-green-500 px-2 py-0 text-xs font-medium text-white">Public</div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="text-xs text-gray-400">{selectedEnrichedCompanyData.value?.companyDomain}</div>
            </div>
          </div>
          <div>
            <DropdownSearch
              options={companySheetsToAdd.map((sheet: any) => {
                return {
                  label: sheet.name,
                  value: sheet.id.toString(),
                  startNode: <ScrollText size={15} key={sheet.id} />,
                }
              })}
              onSelectOption={async (option, unfocus) => {
                await addToListMutation.mutateAsync({
                  sheetId: parseInt(option.value),
                  companyValues: [selectedEnrichedCompanyData.value?.companyName ?? ''],
                })
                let sheetQueryKey = getQueryKey(trpc.sheetRouter.getSheet, parseInt(option.value), 'query')
                let existingTagsQueryKey = getQueryKey(
                  trpc.sheetRouter.findSheetsContainingColumnFilter,
                  {
                    field: 'companyName',
                    value: selectedEnrichedCompanyData.value?.companyName ?? '',
                  },
                  'query',
                )
                queryClient.invalidateQueries(sheetQueryKey)
                queryClient.invalidateQueries(existingTagsQueryKey)
                unfocus()
              }}
              trigger={
                isSmallScreen ? (
                  <BasicButton variant={'gray'} attioStyle className="flex items-center" onClick={() => {}}>
                    <PlusIcon className={'h-[18px]'} />
                  </BasicButton>
                ) : (
                  <BasicButton variant={'gray'} attioStyle className="flex items-center gap-1" onClick={() => {}}>
                    <CardStackPlusIcon />
                    Add to List
                  </BasicButton>
                )
              }
            />
          </div>
        </div>
        <div className="flex min-h-[calc(100vh-200px)] w-full max-w-full flex-1 flex-row">
          <div className="w-full min-w-[calc(100vw-550px)]">
            <div className="flex flex-col gap-0 px-5 pb-1 pt-3">
              <div className="mb-3 flex w-full flex-row items-center justify-between">
                <div className="hover:height-auto h-10 w-full overflow-hidden text-ellipsis whitespace-pre-wrap leading-tight truncate-ellipsis-2-lines">
                  {selectedEnrichedCompanyData.value?.cbCompanyDescription}
                </div>
              </div>
              <div className="flex w-full flex-row items-center  justify-between">
                {investorNames && investorNames.length > 0 && (
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row">
                      {investorNames.slice(0, 4).map((investor, index) => {
                        // @ts-ignore
                        const investorName = typeof investor === 'object' ? investor.text : investor
                        return (
                          <div
                            key={investorName}
                            style={{
                              marginLeft: -8 - index * 1.5,
                              zIndex: 100 - index,
                            }}
                            className="flex h-[35px] w-[35px] items-center justify-center rounded-full border bg-white font-semibold shadow-sm">
                            {investorName && investorName[0]}
                          </div>
                        )
                      })}
                    </div>
                    <div className="text-xs text-gray-400">
                      {investorNames.length > 0 && 'Backed by '}
                      {investorNames.slice(0, 2).map((investor, index) => {
                        // @ts-ignore
                        const name = typeof investor === 'object' ? investor.text : investor
                        return (
                          <span key={index}>
                            {index > 0 ? ', ' : ''}
                            {name}
                          </span>
                        )
                      })}
                      {investorNames.length > 2 && (
                        <>
                          , and {investorNames.length - 2} other
                          {investorNames.length - 2 > 1 ? 's' : ''}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-row items-center gap-2">
                {(pluralSheetsContainingCompany as any[]).map((sheet: any) => {
                  return (
                    <PluralLink href={`/dashboard/sheets/company/${sheet.id}`} key={sheet.id}>
                      <div className="rounded-full bg-gray-200 px-2 py-1 text-xs font-medium text-gray-500 hover:bg-gray-300">
                        {sheet.name}
                      </div>
                    </PluralLink>
                  )
                })}
              </div>
            </div>
            <Tabs
              value={tabValue}
              onValueChange={(newValue) => {
                setTabValue(newValue)
              }}>
              <Tabs.List className="border-b">
                {tabs.map(
                  (tab) =>
                    tab.showTab && (
                      <Tabs.Tab
                        label={tab.label}
                        value={tab.value}
                        isActive={tabValue === tab.value}
                        startIcon={tab.icon}
                        key={tab.value}
                      />
                    ),
                )}
              </Tabs.List>
              {tabs.map((tab) => (tab.showTab ? <Tabs.Content value={tab.value}>{tab.content}</Tabs.Content> : <></>))}
            </Tabs>
            {parseInt(tabValue) <= 3 ? (
              <div>
                {expandedTable ? (
                  <div
                    style={{
                      maxWidth: !isSmallScreen ? 'calc(100vw - 500px)' : '',
                      marginBottom: isSmallScreen ? '110px' : '10px',
                    }}>
                    <div
                      className="flex h-[55px] w-full cursor-pointer items-center gap-2 border-b  border-t pl-5 font-semibold"
                      onClick={() => {
                        setExpandedTable(false)
                      }}>
                      Collapse table <CaretUpIcon className="h-[15px]" />
                    </div>
                    <div
                      style={{
                        width: isSmallScreen ? '100vw' : '100%',
                        flex: 1,
                        padding: isSmallScreen ? '0' : '20px',
                      }}
                      className={'max-w-[500px]' + isSmallScreen ? '' : 'p-5'}
                      id="fundingRoundsTable">
                      <CompanyFundingRoundsTable
                        companyName={selectedEnrichedCompanyData.value?.companyName ?? ''}
                        tableNameNode={
                          <div className={`flex items-center font-medium ${isSmallScreen ? 'pl-2' : ''}`}>
                            Funding Rounds
                          </div>
                        }
                        companyId={companyId?.value ?? ''}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex h-[55px] w-full cursor-pointer items-center gap-2 border-t pl-5 font-semibold"
                    onClick={async () => {
                      setExpandedTable(true)
                      await new Promise((resolve) => setTimeout(resolve, 100))
                      const itemElement = document.querySelector(`#fundingRoundsTable`)
                      itemElement?.scrollIntoView({
                        behavior: 'smooth', // Optional: defines the transition animation
                        block: 'end', // Optional: defines vertical alignment
                        inline: 'start', // Optional: defines horizontal alignment
                      })
                    }}>
                    Show full table <CaretDownIcon className="h-[15px]" />
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className="flex flex-col gap-3 border-t px-5 py-2">
              {selectedEnrichedCompanyData.value?.legalName && (
                <CompanyLabel>
                  <CompanyLabel.Title>
                    <ScaleIcon size={15} />
                    Legal Name
                  </CompanyLabel.Title>
                  <CompanyLabel.Value>{selectedEnrichedCompanyData.value?.legalName}</CompanyLabel.Value>
                </CompanyLabel>
              )}
              {selectedEnrichedCompanyData.value?.foundingDate && (
                <CompanyLabel>
                  <CompanyLabel.Title>
                    <CalendarIcon size={15} />
                    Founded Date
                  </CompanyLabel.Title>
                  <CompanyLabel.Value>{selectedEnrichedCompanyData.value?.foundingDate}</CompanyLabel.Value>
                </CompanyLabel>
              )}
              {selectedEnrichedCompanyData.value?.location && (
                <CompanyLabel>
                  <CompanyLabel.Title>
                    <MapPin size={15} />
                    Location
                  </CompanyLabel.Title>
                  <CompanyLabel.Value>{selectedEnrichedCompanyData.value?.location}</CompanyLabel.Value>
                </CompanyLabel>
              )}
              {safeCastToNumber(selectedEnrichedCompanyData.value?.lastKnownValuation ?? undefined) && (
                <CompanyLabel>
                  <CompanyLabel.Title>
                    <BarChart4 size={15} />
                    Last Valuation
                  </CompanyLabel.Title>
                  <CompanyLabel.Value>
                    {numberToAbbreviatedDollarString(
                      safeCastToNumber(selectedEnrichedCompanyData.value!.lastKnownValuation ?? undefined)!,
                    )}
                  </CompanyLabel.Value>
                </CompanyLabel>
              )}
              {safeCastToNumber(selectedEnrichedCompanyData.value?.totalAmountRaised ?? undefined) && (
                <CompanyLabel>
                  <CompanyLabel.Title>
                    <CoinsIcon size={15} />
                    Total Raised
                  </CompanyLabel.Title>
                  <CompanyLabel.Value>
                    {numberToAbbreviatedDollarString(
                      safeCastToNumber(selectedEnrichedCompanyData.value!.totalAmountRaised ?? undefined)!,
                    )}
                  </CompanyLabel.Value>
                </CompanyLabel>
              )}
              {safeCastToNumber(selectedEnrichedCompanyData.value?.latestHeadcount ?? undefined) && (
                <CompanyLabel>
                  <CompanyLabel.Title>
                    <Users size={15} />
                    Headcount
                  </CompanyLabel.Title>
                  <CompanyLabel.Value>
                    {numberToAbbreviatedString(
                      safeCastToNumber(selectedEnrichedCompanyData.value!.latestHeadcount ?? undefined)!,
                    )}
                  </CompanyLabel.Value>
                </CompanyLabel>
              )}
              {safeCastToNumber(selectedEnrichedCompanyData.value?.latestOpenRoleCount ?? undefined) && (
                <CompanyLabel>
                  <CompanyLabel.Title>
                    <Scroll size={15} />
                    Open Roles
                  </CompanyLabel.Title>
                  <CompanyLabel.Value>
                    {numberToAbbreviatedString(
                      safeCastToNumber(selectedEnrichedCompanyData.value!.latestOpenRoleCount ?? undefined)!,
                    )}
                  </CompanyLabel.Value>
                </CompanyLabel>
              )}
            </div>
          </div>
          {!isMediumScreen && (
            <div className="flex flex  w-[300px] max-w-[300px] flex-col gap-2 gap-3 border-l px-3 pt-3">
              {(isValuationData || isLoading) && (
                <div className="flex h-[94px] min-h-[94px]  w-[266px] items-center justify-center rounded-lg border p-3">
                  {isValuationData && <SmallValuationChart />}
                </div>
              )}

              {(isHeadcountData || isLoading) && (
                <div className="flex h-[94px] min-h-[94px] w-[266px] items-center justify-center rounded-lg border p-3">
                  {isHeadcountData && <SmallHeadcountChart />}
                </div>
              )}

              <div className="flex h-full w-full flex-1 items-center justify-center rounded-lg border p-3"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function SharePriceChart() {
  const { selectedEnrichedCompanyData } = useCompanySelectorContext()
  let fundingData = (selectedEnrichedCompanyData.value?.fundingRounds ?? []).sort(
    (a: any, b: any) =>
      new Date(a.closedDate || a.announcedDate || '').getTime() -
      new Date(b.closedDate || b.announcedDate || '').getTime(),
  )

  let fundingDataGraph = fundingData
    .map((data: any) => ({
      x: new Date(data.closedDate ?? data.announcedDate),
      y: safeCastToNumber(data.pricePerShare),
    }))
    .filter((val: any) => isValidDate(val.x) && val.y)

  return (
    <CompanyStatChart2
      untilToday
      continueLine
      dot
      tickFormatter={(value: any, index: number) => numberToReadableDollarString(value)}
      chartName={'Share Price'}
      scatterData={[{ data: fundingDataGraph, label: 'Share Price' }]}></CompanyStatChart2>
  )
}
export type CompanyStatType =
  | 'valuation'
  | 'financings'
  | 'headcount'
  | 'openRoles'
  | 'revenue'
  | 'marks'
  | 'sharePrice'
// fix these typings
export const CompanyStatTypeValues = [
  'valuation',
  'financings',
  'headcount',
  'openRoles',
  'revenue',
  'marks',
  'sharePrice',
] as const

function ValuationChart() {
  const { selectedEnrichedCompanyData } = useCompanySelectorContext()
  let fundingData = (selectedEnrichedCompanyData.value?.fundingRounds ?? []).sort(
    (a: any, b: any) =>
      new Date(a.closedDate || a.announcedDate || '').getTime() -
      new Date(b.closedDate || b.announcedDate || '').getTime(),
  )

  let fundingDataGraph = fundingData
    .map((data: any) => ({
      x: new Date(data.closedDate ?? data.announcedDate),
      y: safeCastToNumber(data.postMoneyValuation ?? data.announcedPostMoneyValuation),
    }))
    .filter((val: any) => isValidDate(val.x) && val.y)

  return (
    <CompanyStatChart2
      untilToday
      dot
      continueLine
      tickFormatter={(value: any, index: number) => numberToAbbreviatedDollarString(value)}
      variant={'area'}
      chartName={'Valuation'}
      scatterData={[{ data: fundingDataGraph, label: 'Valuation' }]}></CompanyStatChart2>
  )
}

function FinancingsChart() {
  const { selectedEnrichedCompanyData } = useCompanySelectorContext()
  let fundingData = (selectedEnrichedCompanyData.value?.fundingRounds ?? []).sort(
    (a: any, b: any) =>
      new Date(a.closedDate || a.announcedDate || '').getTime() -
      new Date(b.closedDate || b.announcedDate || '').getTime(),
  )

  let fundingDataGraph = fundingData
    .map((data: any) => ({
      x: new Date(data.closedDate || data.announcedDate),
      y: safeCastToNumber(data.closedTotal || data.announcedTotal),
    }))
    .filter((val: any) => isValidDate(val.x) && val.y)

  return (
    <CompanyStatChart2
      untilToday
      tickFormatter={(value: any, index: number) => numberToAbbreviatedDollarString(value)}
      variant={'bar'}
      chartName={'Financings'}
      scatterData={[{ data: fundingDataGraph, label: 'Raised' }]}></CompanyStatChart2>
  )
}

function useHeadcountChartData(companyId?: string) {
  const { selectedEnrichedCompanyData } = useCompanySelectorContext()
  let allHeadcountStats = !!companyId ? [] : selectedEnrichedCompanyData.value?.allHeadcountStats || []

  const enrichedDataQuery = trpc.companies.getEnrichedCompanyData.useQuery(companyId!, {
    enabled: !!companyId,
  })
  allHeadcountStats = enrichedDataQuery.data?.companyData?.allHeadcountStats || allHeadcountStats

  const getTotalHeadcountGraphDataForCompany = () => {
    const statDateToTotalCount = new Map<string, number>()
    const statDateToTotalAdjustedCount = new Map<string, number>()
    for (const stat of allHeadcountStats) {
      const date = stat.statsDate

      if (statDateToTotalCount.has(date)) {
        statDateToTotalCount.set(date, statDateToTotalCount.get(date)! + stat.count)
        if (stat.adjustedCount != null) {
          statDateToTotalAdjustedCount.set(date, statDateToTotalAdjustedCount.get(date)! + stat.adjustedCount)
        }
      } else {
        statDateToTotalCount.set(date, stat.count)
        if (stat.adjustedCount != null) {
          statDateToTotalAdjustedCount.set(date, stat.adjustedCount)
        }
      }
    }
    const totalHeadcountData = Array.from(statDateToTotalCount.entries()).map(([date, count]) => ({
      x: new Date(date),
      y: count,
    }))
    const totalAdjustedHeadcountData = Array.from(statDateToTotalAdjustedCount.entries()).map(([date, count]) => ({
      x: new Date(date),
      y: count,
    }))
    totalHeadcountData.sort((a, b) => a.x.getTime() - b.x.getTime())
    totalAdjustedHeadcountData.sort((a, b) => a.x.getTime() - b.x.getTime())

    return { totalHeadcountData, totalAdjustedHeadcountData }
  }
  return getTotalHeadcountGraphDataForCompany()
}

function useOpenRoleChartData(companyId?: string) {
  const { selectedEnrichedCompanyData } = useCompanySelectorContext()
  let allOpenRoleStats = !!companyId ? [] : selectedEnrichedCompanyData.value?.allOpenRoleStats || []

  const enrichedDataQuery = trpc.companies.getEnrichedCompanyData.useQuery(companyId!, {
    enabled: !!companyId,
  })

  allOpenRoleStats = enrichedDataQuery.data?.companyData?.allOpenRoleStats ?? allOpenRoleStats
  const getTotalOpenRolesGraphDataForCompany = () => {
    const statDateToTotalCount = new Map<string, number>()
    for (const stat of allOpenRoleStats) {
      const date = stat.statsDate
      if (statDateToTotalCount.has(date)) {
        statDateToTotalCount.set(date, statDateToTotalCount.get(date)! + stat.count)
      } else {
        statDateToTotalCount.set(date, stat.count)
      }
    }

    return Array.from(statDateToTotalCount.entries())
      .map(([date, count]) => ({
        x: new Date(date),
        y: count,
      }))
      .sort((a, b) => a.x.getTime() - b.x.getTime())
  }
  return getTotalOpenRolesGraphDataForCompany()
}

function SmallHeadcountChart() {
  const { selectedEnrichedCompanyData } = useCompanySelectorContext()
  const { totalAdjustedHeadcountData } = useHeadcountChartData()
  let headcount = selectedEnrichedCompanyData.value?.latestHeadcount
    ? numberToAbbreviatedString(selectedEnrichedCompanyData.value?.latestHeadcount)
    : '--'
  let percentChangeString = selectedEnrichedCompanyData.value?.oneMonthOverallHeadcountGrowthRate ?? '--'
  let percentChange = safeCastToNumber(percentChangeString.substring(0, percentChangeString.length - 1))
  let color: 'green' | 'red' | 'black' = (percentChange ?? 0) > 0 ? 'green' : percentChange === 0 ? 'black' : 'red'
  let colorString = color === 'green' ? '#228B22' : color

  return (
    <div className="flex flex-row gap-2">
      <SmallChart
        continueLine
        untilToday
        scatterData={[{ data: totalAdjustedHeadcountData.filter((data: any) => data.x && data.y), label: 'Headcount' }]}
        color={color}
      />
      <div className="flex flex-col">
        <div className="text-lg font-bold">{headcount}</div>
        <div
          style={{
            color: colorString,
          }}
          className="flex flex-row items-center gap-2 text-sm">
          {(percentChange ?? 0) > 0 ? <ArrowTopRightIcon /> : <ArrowBottomRightIcon />} {percentChangeString}
        </div>
        <div className="text-sm text-gray-400">Headcount</div>
      </div>
    </div>
  )
}

function SmallValuationChart() {
  const { selectedEnrichedCompanyData } = useCompanySelectorContext()
  let fundingData = (selectedEnrichedCompanyData.value?.fundingRounds ?? []).sort(
    (a: any, b: any) =>
      new Date(a.closedDate || a.announcedDate || '').getTime() -
      new Date(b.closedDate || b.announcedDate || '').getTime(),
  )

  let lastValuation = safeCastToNumber(selectedEnrichedCompanyData.value?.lastKnownValuation ?? '')
  let fundingDataGraph = fundingData
    .map((data: any) => {
      let mostRecentDate =
        new Date(data.closedDate) > new Date(data.announcedDate) ? data.closedDate : data.announcedDate
      return {
        x: new Date(mostRecentDate),
        y: safeCastToNumber(data.postMoneyValuation || data.announcedPostMoneyValuation),
      }
    })
    .filter((val: any) => isValidDate(val.x) && val.y)

  let secondToLastValuation = fundingDataGraph[fundingDataGraph.length - 2]?.y
  let percentChange =
    lastValuation && secondToLastValuation ? (lastValuation - secondToLastValuation) / secondToLastValuation : 0
  let percentChangeString = `${(percentChange * 100).toFixed(2)}%`
  let color: 'green' | 'red' | 'black' = (percentChange ?? 0) > 0 ? 'green' : percentChange === 0 ? 'black' : 'red'
  let colorString = color === 'green' ? '#228B22' : color
  if (!lastValuation) {
    return <div></div>
  }
  return (
    <div className="flex flex-row gap-2">
      <SmallChart
        untilToday
        scatterData={[{ data: fundingDataGraph, label: 'Valuation' }]}
        color={color}
        linear={false}
        continueLine
      />
      <div className="flex flex-col">
        <div className="text-lg font-bold">{lastValuation ? numberToAbbreviatedDollarString(lastValuation) : ''}</div>
        <div
          style={{
            color: colorString,
          }}
          className="flex flex-row items-center gap-2 text-sm">
          {(percentChange ?? 0) > 0 ? <ArrowTopRightIcon /> : <ArrowBottomRightIcon />} {percentChangeString}
        </div>
        <div className="text-sm text-gray-400">Valuation</div>
      </div>
    </div>
  )
}

function CompanyLabel(props: PropsWithChildren<{}>) {
  return <div className="flex flex-row items-center gap-2">{props.children}</div>
}

CompanyLabel.Title = function CompanyLabelTitle(props: PropsWithChildren<{}>) {
  return (
    <div className="flex w-[125px] max-w-[125px] flex-row items-center gap-2 truncate text-sm font-medium text-gray-400">
      {props.children}
    </div>
  )
}

CompanyLabel.Value = function CompanyLabelValue(props: PropsWithChildren<{}>) {
  return <div className="text-md text-black">{props.children}</div>
}
