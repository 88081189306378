import { useQuery } from '@tanstack/react-query'
import { ColumnDef, FilterFnOption, SortingFnOption, createColumnHelper } from '@tanstack/react-table'
import { DateCellFormat, DollarsCellFormat } from 'components/editable-table/EditableTextCellFormat'
import { DeleteCell, columnType, renderEditableCell } from 'components/editable-tanstack-table/EditableTanstackTable'
import { format } from 'date-fns'
import { CompanyAvatar, getSearchableCompanies } from 'pages/admin/AdminCompanySheetPage'
import SheetPage from 'pages/admin/AdminSheetPage'
import { indicationsRouter } from 'plural-trpc/routers/indicationsRouter'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useMemo } from 'react'
import { ArrayElement, Optional } from 'safety'
import { DropdownSearchOption } from 'several/components/DropdownSearch'
import { Fund } from 'several/hooks/Funds'
import { RouterOutputs, trpc } from 'utils/trpc'

type IndicationType = 'ASK' | 'BID'
type RouterIndicationsRow = ArrayElement<RouterOutputs['indications']['indicationQuery']>

export default function IndicationsSheetPage(props: { isLoading?: boolean }) {
  let { isLoading: isAuthLoading } = usePluralAuth()

  let indicationsQuery = trpc.indications.indicationQuery.useQuery(undefined, {
    enabled: !isAuthLoading,
  })
  const indications = indicationsQuery.data ?? []
  const trpcContext = trpc.useContext()

  //   let columns: ColumnDef<Fund, any>[] = useFundsColumns({})
  // const { navigate, pushNavigate } = usePluralNavigation()

  let companiesQuery = useQuery({
    queryKey: ['searchableCompanies'],
    queryFn: getSearchableCompanies,
  })

  let defaultCellFn = (cell: any) => {
    const initialValue = cell.getValue()
    let _column = cell.column.columnDef.meta!
    return renderEditableCell(
      initialValue,
      _column as unknown as columnType<RouterIndicationsRow, any>,
      cell.row.original,
      (row) => row.id,
      // inputs.bgColor,
    )
  }

  let columnHelper = createColumnHelper<RouterIndicationsRow>()
  let indicationCreateMutation = trpc.indications.indicationCreate.useMutation()
  let indicationUpdateMutation = trpc.indications.indicationUpdate.useMutation()

  let companies = companiesQuery.data ?? []

  let columns: ColumnDef<RouterIndicationsRow>[] = useMemo(
    () => [
      columnHelper.accessor('massiveCompanyId', {
        header: 'Company',
        id: '1',
        sortingFn: 'stringFn' as SortingFnOption<RouterIndicationsRow>,
        size: 300,
        meta: {
          inputType: 'autoComplete',
          createProps: {
            createdObjectKey: 'massiveCompanyId',
            isRequired: true,
          },
          autoCompleteOptions: companies.map((company, i) => ({
            label: company.companyName,
            index: i,
            id: company.companyId,
            additionalSearchString: company.companyDomain,
            value: company.companyId,
            startNode: (
              <div className="mr-3">
                <CompanyAvatar
                  logo={company.companyS3LogoUrl}
                  alt={company.companyName}
                  altLetter={company.companyName.toUpperCase()}
                  size={20}
                />
              </div>
            ),
          })),
          className: 'z-30 sticky left-0 bg-white',
          headerClassName: 'z-40 sticky left-0 bg-white',
          isImmovable: true,
          isUnhideable: true,
          // @ts-ignore
          updateRemote: async (row: RouterIndicationsRow, newValue: Optional<IndicationType>) => {
            if (!newValue) return
            await indicationUpdateMutation.mutateAsync({
              id: row.id,
              type: row.type,
              massiveCompanyId: newValue,
              pricePerShare: row.pricePerShare!,
              valuation: row.valuation!,
              size: row.size,
              isActive: row.isActive,
            })
            await indicationsQuery.refetch()
          },
        },
        cell: (cell) => {
          return (
            <div className={`flex max-w-full items-center gap-3 truncate p-2`}>
              {/* <InputCheckbox checked={cell.row.getIsSelected()} onChange={cell.row.getToggleSelectedHandler()} /> */}

              {defaultCellFn(cell)}
            </div>
          )
        },
      }) as ColumnDef<RouterIndicationsRow>,
      columnHelper.accessor('type', {
        header: 'Type',
        id: '2',
        size: 100,
        sortingFn: 'singleSelectFn' as SortingFnOption<RouterIndicationsRow>,
        filterFn: 'singleSelectFn' as FilterFnOption<RouterIndicationsRow>,
        // cell: (cell) => <div className="flex flex-row truncate pl-1">{cell.getValue()}</div>,
        meta: {
          singleSelectOptions: [
            { label: 'ASK', value: 'ASK' },
            { label: 'BID', value: 'BID' },
          ],
          createProps: {
            isRequired: true,
            createdObjectKey: 'type',
          },
          inputType: 'multiSelect',
          options: ['ASK', 'BID'],
          // @ts-ignore
          updateRemote: async (row: RouterIndicationsRow, newValue: Optional<IndicationType>) => {
            if (!newValue) return
            await indicationUpdateMutation.mutateAsync({
              id: row.id,
              type: newValue,
              massiveCompanyId: row.massiveCompanyId,
              pricePerShare: row.pricePerShare!,
              valuation: row.valuation!,
              size: row.size,
              isActive: row.isActive,
            })
            await indicationsQuery.refetch()
          },
        },
      }) as ColumnDef<RouterIndicationsRow>,
      columnHelper.accessor('pricePerShare', {
        header: 'Price Per Share',
        id: '3',
        sortingFn: 'numberFn' as SortingFnOption<RouterIndicationsRow>,
        filterFn: 'numberFn' as FilterFnOption<RouterIndicationsRow>,
        size: 200,
        meta: {
          createProps: {
            isRequired: false,
            createdObjectKey: 'pricePerShare',
          },
          inputType: 'input',
          textCellFormat: DollarsCellFormat,
          // @ts-ignore
          updateRemote: async (row: RouterIndicationsRow, newValue: Optional<number>) => {
            if (!newValue) return
            await indicationUpdateMutation.mutateAsync({
              id: row.id,
              pricePerShare: newValue,
              massiveCompanyId: row.massiveCompanyId,
              size: row.size,
              valuation: row.valuation!,
              type: row.type,
              isActive: row.isActive,
            })
            await indicationsQuery.refetch()
          },
          placeholder: 'Price Per Share',
        },
      }) as ColumnDef<RouterIndicationsRow>,
      columnHelper.accessor('valuation', {
        header: 'Valuation',
        id: '4',
        sortingFn: 'numberFn' as SortingFnOption<RouterIndicationsRow>,
        filterFn: 'numberFn' as FilterFnOption<RouterIndicationsRow>,
        meta: {
          createProps: {
            isRequired: false,
            createdObjectKey: 'valuation',
          },
          inputType: 'input',
          textCellFormat: DollarsCellFormat,
          // @ts-ignore
          updateRemote: async (row: RouterIndicationsRow, newValue: Optional<number>) => {
            if (!newValue) return
            await indicationUpdateMutation.mutateAsync({
              id: row.id,
              massiveCompanyId: row.massiveCompanyId,
              pricePerShare: row.pricePerShare!,
              size: row.size,
              type: row.type,
              valuation: newValue,
              isActive: row.isActive,
            })
            await indicationsQuery.refetch()
          },
          placeholder: 'Valuation',
        },
      }) as ColumnDef<RouterIndicationsRow>,
      columnHelper.accessor('size', {
        header: 'Size',
        id: '5',
        sortingFn: 'numberFn' as SortingFnOption<RouterIndicationsRow>,
        filterFn: 'numberFn' as FilterFnOption<RouterIndicationsRow>,
        meta: {
          createProps: {
            isRequired: true,
            createdObjectKey: 'size',
          },
          inputType: 'input',
          textCellFormat: DollarsCellFormat,
          // @ts-ignore
          updateRemote: async (row: RouterIndicationsRow, newValue: Optional<number>) => {
            if (!newValue) return
            await indicationUpdateMutation.mutateAsync({
              id: row.id,
              size: newValue,
              massiveCompanyId: row.massiveCompanyId,
              pricePerShare: row.pricePerShare!,
              valuation: row.valuation!,
              type: row.type,
              isActive: row.isActive,
            })
            await indicationsQuery.refetch()
          },
          placeholder: 'Size',
        },
      }) as ColumnDef<RouterIndicationsRow>,
      columnHelper.accessor('createdAt', {
        header: 'Active Since',
        id: '6',
        size: 200,
        cell: (cell) => {
          return <div className='ml-2'>{cell.getValue() ? format(new Date(cell.getValue() as string), 'MMM dd yyyy') : null}</div>
        },
        sortingFn: 'dateFn' as SortingFnOption<RouterIndicationsRow>,
        filterFn: 'dateFn' as FilterFnOption<RouterIndicationsRow>,
      }) as ColumnDef<RouterIndicationsRow>,
      columnHelper.accessor((row) => `${row.createdByUser.firstName} ${row.createdByUser.lastName}`, {
        header: 'Created By',
        id: '8',
        sortingFn: 'stringFn' as SortingFnOption<RouterIndicationsRow>,
        filterFn: 'stringFn' as FilterFnOption<RouterIndicationsRow>,
        cell: (cell) => {
          return <div className='ml-2'>{cell.row.original.createdByUser.firstName} {cell.row.original.createdByUser.lastName}</div>
        }
      }) as ColumnDef<RouterIndicationsRow>,
      columnHelper.accessor((row) => (row.isActive), {
        header: 'Is Active?',
        id: '7',
        size: 200,
        // sortingFn: 'booleanFn' as SortingFnOption<RouterIndicationsRow>,
        filterFn: 'booleanFn' as FilterFnOption<RouterIndicationsRow>,
        meta: {
          inputType: 'boolean',
          // @ts-ignore
          updateRemote: async (row: RouterIndicationsRow, newValue: Optional<boolean>) => {
            // optimistically update
            trpcContext.indications.indicationQuery.setData(undefined, (oldData: RouterIndicationsRow[] | undefined) =>
              oldData ? oldData.map(item => item.id === row.id ? { ...item, isActive: newValue ?? false } : item) : oldData
            );

            try {
              await indicationUpdateMutation.mutateAsync({
                id: row.id,
                size: row.size,
                massiveCompanyId: row.massiveCompanyId,
                pricePerShare: row.pricePerShare!,
                valuation: row.valuation!,
                type: row.type,
                isActive: newValue ?? false
              });
            } catch (error) {
              // undo optimistic update
              trpcContext.indications.indicationQuery.setData(undefined, (oldData: RouterIndicationsRow[] | undefined) =>
                oldData ? oldData.map(item => item.id === row.id ? row : item) : oldData
              );
            }
          },
        },
      }) as ColumnDef<RouterIndicationsRow>,
    ],
    [columnHelper],
  )

  return (
    <>
      <SheetPage
        paginate
        defaultSheetId={10}
        backBreadcrumb={{ href: '/dashboard/sheets/indications', title: 'Indications' }}
        getRowId={(row: any) => row.id}
        isLoading={indicationsQuery.isLoading || props.isLoading}
        columns={columns as ColumnDef<unknown>[]}
        data={indications}
        filterFns={{}}
        sortingFns={{}}
        onRowCreate={async (newRow) => {
          const data = newRow as RouterIndicationsRow
          await indicationCreateMutation.mutateAsync({
            massiveCompanyId: (data.massiveCompanyId as unknown as DropdownSearchOption).value,
            type: data.type,
            pricePerShare: data.pricePerShare,
            valuation: data.valuation,
            isActive: true,
            size: data.size,
          })
          indicationsQuery.refetch()
        }}

      // onNavigate={(newId) => {
      //     navigate(`/fund-upload`)
      // }}
      // onNavigatePush={(newId) => {
      //     pushNavigate(`/dashboard/sheets/funds/${newId}`)
      // }}
      />
    </>
  )
}
