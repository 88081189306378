import { DotsVerticalIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { TextFieldInput, TextFieldRoot, TextFieldSlot } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import { BasicButton } from 'components/BasicButton'
import { Dropdown } from 'components/Dropdown'
import { ArrowLeft, LogOut, ScrollText } from 'lucide-react'
import { RouterSingleSheet } from 'pages/admin/AdminSheetPage'
import { createContext, PropsWithChildren, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router'
import DropdownSearch, { DropdownSearchOption } from 'several/components/DropdownSearch'
import SeveralSideNav, { DashHeaderPiece } from 'several/components/SeveralSidenav'
import { useHasPreviousPage, useIsSmallScreen } from 'several/hooks/navigation'
import { CompanyAvatar } from 'several/pages/FundingRoundSheetPage'
import { trpc } from 'utils/trpc'
import { getSearchableCompanies } from '../../src/pages/admin/AdminCompanySheetPage'
import { usePluralNavigation } from './NavigationProvider'
import { usePluralAuth } from './PluralAuthProvider'

type ActionButton = {
  text?: string
  icon?: ReactNode
  onClick?: () => void
}
const NavigationUIContext = createContext({
  setActionButtons: (() => {}) as React.Dispatch<React.SetStateAction<ActionButton[]>>,
  setSideNavOpen: (() => {}) as React.Dispatch<React.SetStateAction<boolean>>,
  setTopNavOpen: (() => {}) as React.Dispatch<React.SetStateAction<boolean>>,
})
export function usePluralNavigationUI() {
  return useContext(NavigationUIContext)
}

function useIsMdScreen() {
  return useIsSmallScreen('md')
}

function useDefaultButtons() {
  let { logout } = usePluralAuth()
  return [
    {
      text: 'Logout',
      onClick: () =>
        logout({
          logoutParams: { returnTo: window.location.origin },
        }),
      icon: <LogOut size={20} />,
    },
  ]
}
export default function NavigationUIProvider(props: PropsWithChildren<{}>) {
  let defaultButtons = useDefaultButtons()
  const [actionButtons, setActionButtons] = useState<ActionButton[]>(defaultButtons)
  let isSmallScreen = useIsSmallScreen()
  let isMdScreen = useIsMdScreen()
  const [sideNavOpen, setSideNavOpen] = useState(true)
  const [topNavOpen, setTopNavOpen] = useState(true)

  return (
    <NavigationUIContext.Provider value={{ setActionButtons, setSideNavOpen, setTopNavOpen }}>
      <div className="flex h-[100vh] w-[100vw] flex-row">
        {!isSmallScreen && sideNavOpen ? <SeveralSideNav /> : undefined}
        <div className="flex w-full flex-col overflow-hidden">
          {topNavOpen ? (
            <TopNav
              sideNavOpen={sideNavOpen && !isSmallScreen}
              actionButtons={
                <>
                  {isMdScreen && actionButtons.length > 0 ? (
                    <Dropdown
                      button={
                        <div className="cursor-pointer rounded-full px-2 py-1 hover:bg-gray-100">
                          <DotsVerticalIcon height="20" />
                        </div>
                      }>
                      {actionButtons.map((button, index) => {
                        return (
                          <div
                            key={index}
                            onClick={button.onClick}
                            className="flex w-full flex-row items-center gap-2 p-2 hover:bg-gray-100">
                            {button.icon}
                            {button.text}
                          </div>
                        )
                      })}
                    </Dropdown>
                  ) : (
                    <div className="flex flex-row items-center gap-2">
                      {actionButtons.map((button, index) => {
                        return (
                          <BasicButton key={index} onClick={button.onClick} variant="gray" attioStyle>
                            {button.icon}
                            {button.text}
                          </BasicButton>
                        )
                      })}
                    </div>
                  )}
                </>
              }
            />
          ) : undefined}
          <div className={`flex h-full w-full flex-col overflow-scroll`}>
            <Outlet />
          </div>
        </div>
      </div>
    </NavigationUIContext.Provider>
  )
}

function TopNav(props: { actionButtons: ReactNode; sideNavOpen: boolean }) {
  let isSmallScreen = useIsSmallScreen()
  let height = isSmallScreen ? 50 : 60
  let { navigate } = usePluralNavigation()
  let hasBack = useHasPreviousPage()
  return (
    <div
      style={{ height: height, minHeight: height, width: '100%', flexShrink: 0, flex: 1 }}
      className="flex flex-row items-center">
      {!props.sideNavOpen && <DashHeaderPiece />}
      <div className="flex h-full w-full flex-1 flex-row items-center border-b pl-2">
        {hasBack ? (
          <div className="rounded-full p-1 hover:bg-gray-100">
            <ArrowLeft
              style={{ cursor: 'pointer' }}
              color="gray"
              size={20}
              onClick={() => {
                // @ts-ignore
                navigate(-1)
              }}
            />
          </div>
        ) : undefined}
        <div
          style={{
            position: 'absolute',
            height: '40px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}>
          <NavSearchBar />
        </div>
        <div className="ml-auto mr-3 flex flex-row items-center">
          {props.actionButtons ?? (
            <BasicButton variant="gray" attioStyle>
              Follow
            </BasicButton>
          )}
        </div>
      </div>
    </div>
  )
}

export function useStaticActionButtons(buttons: ActionButton[]) {
  let { setActionButtons } = usePluralNavigationUI()
  let defaultButtons = useDefaultButtons()
  useEffect(() => {
    setActionButtons(buttons)
    return () => {
      setActionButtons(defaultButtons)
    }
  }, [])
}

export function useClosedSideNav() {
  let { setSideNavOpen } = usePluralNavigationUI()

  useEffect(() => {
    setSideNavOpen(false)
    return () => {
      setSideNavOpen(true)
    }
  }, [])
}

function NavSearchBar() {
  let [searchString, setSearchString] = useState('')
  let companiesQuery = useQuery({
    queryKey: ['searchableCompanies'],
    queryFn: getSearchableCompanies,
  })
  let allSheetsQuery = trpc.sheetRouter.getAllSheets.useQuery()

  let dropDownOptions: DropdownSearchOption[] = useMemo(() => {
    let companies = companiesQuery.data ?? []
    let sheets = allSheetsQuery.data ?? []

    return [
      ...companies.map((company) => {
        return {
          label: company.companyName,
          value: `/company/${company.companyId}`,
          style: { padding: '10px' },
          startNode: (
            <CompanyAvatar
              logo={company.companyS3LogoUrl}
              alt={company.companyName}
              altLetter={company.companyName.toUpperCase()}
              size={20}
            />
          ),
          endNode: <div className="text-xs text-gray-500">Headcount: {company.latestHeadcount}</div>,
        }
      }),
      ...sheets.map((sheet: RouterSingleSheet) => {
        return {
          label: sheet.name,
          value: `/dashboard/sheets/${sheet.entityType === 'COMPANY' ? 'company' : 'funding'}/${sheet.id}`,
          style: { padding: '10px' },
          startNode: <ScrollText size={20} />,
        }
      }),
    ]
  }, [companiesQuery.data, allSheetsQuery.data])
  let { navigate } = usePluralNavigation()
  let isSmallScreen = useIsSmallScreen()
  let innerWidth = isSmallScreen ? 200 : 300
  return (
    <DropdownSearch
      manualHoveredIndex={-1}
      manualSearchString={searchString}
      options={dropDownOptions}
      onSelectOption={(option) => {
        setSearchString('')
        navigate(option.value)
      }}
      onBlur={() => {
        setSearchString('')
      }}
      width={innerWidth + 20}
      trigger={
        <TextFieldRoot size={'3'} radius="small">
          <TextFieldInput
            style={{ width: innerWidth }}
            placeholder="Search"
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value)
            }}
          />
          <TextFieldSlot>
            <MagnifyingGlassIcon height="18" width="18" />
          </TextFieldSlot>
        </TextFieldRoot>
      }></DropdownSearch>
  )
}
