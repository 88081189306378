import * as Popover from '@radix-ui/react-popover'
import { useMutation, useQuery } from '@tanstack/react-query'
import { CellContext, ColumnDef, FilterFnOption, SortingFnOption, createColumnHelper } from '@tanstack/react-table'
import { TableCellWithDropdown } from 'components/Dropdown'
import { InputCheckbox } from 'components/editable-table/CheckBox'
import {
  BigIntCellFormat,
  BigIntDollarsCellFormat,
  DateStringCellFormat,
  DollarsCellFormat,
  PercentCellFormat,
  StringCellFormat,
} from 'components/editable-table/EditableTextCellFormat'
import { columnType, renderEditableCell } from 'components/editable-tanstack-table/EditableTanstackTable'
import { ChevronDown, ChevronRight } from 'lucide-react'
import { cellClassName, getSearchableCompanies } from 'pages/admin/AdminCompanySheetPage'
import {
  numberToAbbreviatedDollarString,
  numberToReadableDollarString,
  numberToReadableString,
} from 'plural-shared/utils'
import { PluralLink, PluralOutsideLink } from 'providers/NavigationProvider'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { patchFundingRound } from 'several/actions/FundingRounds'
import { MasterFundingRound, UpdateMassiveFundingRoundData, allFundingRoundTypes } from 'several/models/FundingRounds'
import { CompanyAvatar } from 'several/pages/FundingRoundSheetPage'

const publicColumnToAccessors: { [key: string]: string[] } = {
  total: ['closedTotal', 'announcedTotal'],
  postMoneyValuation: ['postMoneyValuation', 'announcedPostMoneyValuation'],
  date: ['closedDate', 'announcedDate'],
}
export function getFundingRoundSortingFns<T>() {
  return {
    unifiedDateFn: (a: T, b: T, field: string) => {
      let aAnswer: string = (a['closedDate' as keyof T] || a['announcedDate' as keyof T]) as unknown as string
      let bAnswer: string = (b['closedDate' as keyof T] || b['announcedDate' as keyof T]) as unknown as string

      if (!aAnswer && !bAnswer) {
        return 0
      }
      if (!aAnswer) {
        return -1
      }
      if (!bAnswer) {
        return 1
      }
      if (aAnswer === bAnswer) {
        return (
          (a['pricePerShare' as keyof T] as unknown as number) - (b['pricePerShare' as keyof T] as unknown as number)
        )
      }
      let firstDate = new Date(aAnswer as unknown as string)
      let secondDate = new Date(bAnswer as unknown as string)
      return firstDate.getTime() - secondDate.getTime()
    },
  }
}
function isValidLink(url: string): boolean {
  const pattern =
    /^(https?:\/\/)?((([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,})|(([0-9]{1,3}\.){3}[0-9]{1,3}))(:[0-9]{1,5})?(\/.*)?$/
  return pattern.test(url)
}
export function titleCase(str: string | undefined): string {
  if (!str) return ''
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .split('/')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('/')
}

function formatDate(date: Date) {
  return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric', day: 'numeric', timeZone: 'UTC' })
}
function cleanUrl(
  url: string,
  stringsToRemove: string[] = ['https://', 'http://', '.com', '.org', '.net', '.edu', 'www.', '.io'],
): string {
  let cleanedUrl = url
  for (const str of stringsToRemove) {
    cleanedUrl = cleanedUrl.replace(new RegExp(str, 'gi'), '')
  }
  return cleanedUrl
}

export const hackyEditorIds = [1, 304, 313, 314]
export function useFundingRoundColumns(inputs: {
  cellClassName?: string
  includeCompanyName?: boolean
  onSuccess?: (newRound: MasterFundingRound, oldId: string) => void
  bgColor?: string
}) {
  let { user } = usePluralAuth()

  let columnHelper = createColumnHelper<MasterFundingRound>()
  let companiesQuery = useQuery({
    queryKey: ['searchableCompanies'],
    queryFn: getSearchableCompanies,
  })

  let isEditor = hackyEditorIds.indexOf(user?.id ?? 0) !== -1
  let cellClassName = inputs.cellClassName ?? `max-w-full truncate p-2 ${isEditor ? 'cursor-not-allowed' : ''}`
  let isNotEditable = (cell: any) => (cell.row.original.subRows?.length ?? 0) > 0 || !isEditor

  let patchFundingRoundMutation = useMutation({
    mutationFn: (variables: { fundingRound: UpdateMassiveFundingRoundData }) => patchFundingRound(variables)(),
    mutationKey: ['patchFundingRound'],
    onSuccess: (newRound, oldVars) => {
      // return inputs.onSuccess?.(newRound, oldVars.fundingRound.id)
    },
  })

  let columnsMemo = useMemo(() => {
    let defaultCellFn = (cell: any) => {
      const initialValue = cell.getValue()
      let _column = cell.column.columnDef.meta!
      return (
        <SourceDivCell cell={cell}>
          {renderEditableCell(
            initialValue,
            _column as unknown as columnType<MasterFundingRound, any>,
            cell.row.original,
            (row) => row.id,
            inputs.bgColor,
            true,
          )}
        </SourceDivCell>
      )
    }
    function getUpdateRemote<T>(field: string) {
      return async (row: any, newValue: T) => {
        return patchFundingRoundMutation.mutate({
          fundingRound: {
            id: row.id,
            companyId: row.companyId,
            [field]: newValue,
            adminEmail: user?.email ?? '',
          },
        })
      }
    }
    let companies = companiesQuery.data ?? []
    // let fundingRounds = fundingRoundsQuery.data ?? []
    function editorColumn(column: ColumnDef<MasterFundingRound, any>): ColumnDef<MasterFundingRound, any>[] {
      return isEditor ? [column] : []
    }

    function publicColumn(column: ColumnDef<MasterFundingRound, any>): ColumnDef<MasterFundingRound, any>[] {
      return isEditor ? [] : [column]
    }
    let columns: ColumnDef<MasterFundingRound, any>[] = [
      columnHelper.accessor('series', {
        header: 'Series',
        size: 200,
        id: 'series',

        cell: (cell) => {
          return (
            <div
              className={`flex max-w-full items-center gap-3 truncate p-2`}
              style={{
                paddingLeft: cell.row.depth * 18 + 8,
              }}>
              <>
                <div className="item-center flex h-full w-[20px] shrink-0 justify-center">
                  {cell.row.getCanExpand() ? (
                    <button
                      {...{
                        onClick: cell.row.getToggleExpandedHandler(),
                        style: { cursor: 'pointer', width: '100%' },
                      }}>
                      {cell.row.getIsExpanded() ? (
                        <ChevronDown className="h-[13px] w-[13px]" />
                      ) : (
                        <ChevronRight className="h-[13px] w-[13px]" />
                      )}
                    </button>
                  ) : (
                    <div className="w-[20px]"></div>
                  )}
                </div>

                <InputCheckbox
                  disabled={!cell.row.getCanSelect()}
                  checked={cell.row.getIsSelected()}
                  onChange={cell.row.getToggleSelectedHandler()}
                />
                {isNotEditable(cell)
                  ? titleCase(cell.getValue() as string)
                  : renderEditableCell(
                      cell.getValue(),
                      cell.column.columnDef.meta! as unknown as columnType<MasterFundingRound, any>,
                      cell.row.original,
                      (row) => row.id,
                      inputs.bgColor,
                    )}
              </>
            </div>
          )
        },
        sortingFn: 'stringFn' as SortingFnOption<MasterFundingRound>,
        filterFn: 'stringFn' as FilterFnOption<MasterFundingRound>,
        meta: {
          selectAllOptions: true,
          className: 'z-30 sticky left-0 bg-white',
          headerClassName: 'z-40 sticky left-0 bg-white',
          isImmovable: true,
          isUnhideable: true,
          inputType: 'autoComplete',
          autoCompleteOptions: allFundingRoundTypes.map((type, i) => {
            return {
              value: type,
              label: titleCase(type),
            }
          }),
          noPadding: true,
          updateRemote: getUpdateRemote<string>('series'),
          // createProps: {
          //   // @ts-ignore
          //   createdObjectKey: 'series',
          //   isRequired: true,
          // },
        },
      }),
      columnHelper.accessor('shareClass', {
        header: 'Share Class',
        id: 'shareClass',

        cell: (cell) => {
          if (!isNotEditable(cell)) {
            return defaultCellFn(cell)
          }

          return <SourceDivCell cell={cell}>{titleCase(cell.getValue())}</SourceDivCell>
        },
        meta: {
          inputType: 'input',
          textCellFormat: StringCellFormat,
          updateRemote: getUpdateRemote<string>('shareClass'),
        },
        sortingFn: 'stringFn' as SortingFnOption<MasterFundingRound>,
        filterFn: 'stringFn' as FilterFnOption<MasterFundingRound>,
      }),

      ...(isEditor ? [] : []),

      ...(inputs.includeCompanyName
        ? [
            columnHelper.accessor('companyName', {
              id: 'companyName',
              header: 'Company',
              enableGlobalFilter: true,
              minSize: 200,
              meta: {
                singleSelectPlaceholder: 'Search for a company',
                inputType: 'multiSelect',
                updateRemote: getUpdateRemote<string>('companyName'),

                // @ts-ignore
                autoCompleteOptions: companies.map((company) => company.companyName),
                singleSelectOptions: companies.map((company, index) => ({
                  label: company.companyName,
                  // CHANGE TO ID WHEN WE CAN
                  value: company.companyName,
                  index,
                  style: {
                    padding: '10px',
                  },
                  startNode: (
                    <CompanyAvatar
                      logo={company.companyS3LogoUrl}
                      alt={company.companyName}
                      altLetter={company.companyName.toUpperCase()}
                      size={20}
                    />
                  ),
                  endNode: <div className="font-gray-500 text-xs font-light">Headcount: {company.latestHeadcount}</div>,
                })),
              },
              filterFn: 'singleSelectFn' as FilterFnOption<MasterFundingRound>,
              size: 300,
              sortingFn: 'stringFn' as SortingFnOption<MasterFundingRound>,
              cell: (cell) => {
                let company = companies.find((company) => company.companyId === cell.row.original.companyId)
                return (
                  <div className={`flex max-w-full items-center gap-3 truncate p-2`}>
                    {/* <InputCheckbox checked={cell.row.getIsSelected()} onChange={cell.row.getToggleSelectedHandler()} /> */}
                    <div>
                      <CompanyAvatar
                        logo={company?.companyS3LogoUrl}
                        alt={company?.companyName ?? ''}
                        altLetter={company?.companyName[0]?.toUpperCase() ?? cell.getValue()[0]?.toUpperCase()}
                      />
                    </div>
                    <PluralLink href={`/company/${cell.row.original.companyId}`}>
                      {cell.getValue() as string}
                    </PluralLink>
                  </div>
                )
              },
            }),
          ]
        : []),
      ...editorColumn(
        columnHelper.accessor('announcedDate', {
          header: 'Announced Date',
          id: 'announcedDate',
          cell: (cell) => {
            if (!isNotEditable(cell)) {
              return defaultCellFn(cell)
            }
            let earliestDate: Date | undefined = undefined
            let latestDate: Date | undefined = undefined
            ;(cell.row.original.subRows ?? []).forEach((subRow) => {
              if (!subRow.announcedDate) {
                return
              }
              if (!earliestDate || new Date(subRow.announcedDate).getTime() < earliestDate.getTime()) {
                earliestDate = new Date(subRow.announcedDate)
              }
              if (!latestDate || new Date(subRow.announcedDate).getTime() > latestDate.getTime()) {
                latestDate = new Date(subRow.announcedDate)
              }
            })
            return (
              <SourceDivCell cell={cell}>
                {cell.getValue() && cell.getValue()
                  ? formatDate(new Date(cell.getValue()))
                  : cell.row.original.subRows?.find((subRow) => subRow.announcedDate)
                    ? `${formatDate(earliestDate!)}  -  ${formatDate(latestDate!)}`
                    : ''}
              </SourceDivCell>
            )
          },
          meta: {
            inputType: 'input',
            textCellFormat: DateStringCellFormat,
            updateRemote: getUpdateRemote<string>('announcedDate'),
          },

          sortingFn: 'dateFn' as SortingFnOption<MasterFundingRound>,
          filterFn: 'dateFn' as FilterFnOption<MasterFundingRound>,
        }),
      ),

      ...editorColumn(
        columnHelper.accessor('closedDate', {
          id: 'closedDate',
          header: 'Closed Date',

          cell: (cell) => {
            if (!isNotEditable(cell)) {
              return defaultCellFn(cell)
            }
            return (
              <SourceDivCell cell={cell}>
                {cell.getValue() ? formatDate(new Date(cell.getValue())) : undefined}
              </SourceDivCell>
            )
          },
          meta: {
            inputType: 'input',
            textCellFormat: DateStringCellFormat,
            updateRemote: getUpdateRemote<string>('closedDate'),
          },
          sortingFn: 'dateFn' as SortingFnOption<MasterFundingRound>,
          filterFn: 'dateFn' as FilterFnOption<MasterFundingRound>,
        }),
      ),
      columnHelper.accessor(
        (row) => {
          let dateA = row.announcedDate ? new Date(row.announcedDate) : undefined
          let dateB = row.closedDate ? new Date(row.closedDate) : undefined
          return dateA && dateB ? (dateA.getTime() > dateB.getTime() ? dateA : dateB) : dateA ?? dateB
        },
        {
          id: 'date',
          header: 'Date',
          sortingFn: 'unifiedDateFn' as SortingFnOption<MasterFundingRound>,
          filterFn: 'unifiedDateFn' as FilterFnOption<MasterFundingRound>,
          cell: (cell) => (
            <SourceDivCell cell={cell}>
              {cell.getValue() ? formatDate(new Date(cell.getValue()!)) : undefined}
            </SourceDivCell>
          ),
        },
      ),

      ...editorColumn(
        columnHelper.accessor('announcedTotal', {
          header: 'Announced Total',
          id: 'announcedTotal',
          cell: (cell) => {
            if (!isNotEditable(cell)) {
              return defaultCellFn(cell)
            }
            return (
              <SourceDivCell cell={cell}>
                {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue()) : undefined}
              </SourceDivCell>
            )
          },
          meta: {
            inputType: 'input',
            textCellFormat: BigIntDollarsCellFormat,
            updateRemote: getUpdateRemote<number>('announcedTotal'),
            // createProps: {
            //   // @ts-ignore
            //   createdObjectKey: 'announcedTotal',
            //   isRequired: false,
            // },
          },
          sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
          filterFn: 'numberFn' as FilterFnOption<MasterFundingRound>,
        }),
      ),
      ...editorColumn(
        columnHelper.accessor('closedTotal', {
          header: 'Closed Total',
          id: 'closedTotal',
          cell: (cell) => {
            if (!isNotEditable(cell)) {
              return defaultCellFn(cell)
            }
            return (
              <SourceDivCell cell={cell}>
                {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue()) : undefined}
              </SourceDivCell>
            )
          },
          meta: {
            inputType: 'input',
            textCellFormat: BigIntDollarsCellFormat,
            updateRemote: getUpdateRemote<number>('closedTotal'),
          },
          filterFn: 'numberFn' as FilterFnOption<MasterFundingRound>,
          sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
        }),
      ),
      ...publicColumn(
        columnHelper.accessor(
          (row) => {
            return row.closedTotal || row.announcedTotal
          },
          {
            header: 'Total',
            id: 'total',
            cell: (cell) => {
              if (!isNotEditable(cell)) {
                return defaultCellFn(cell)
              }
              return (
                <SourceDivCell cell={cell}>
                  {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue()) : undefined}
                </SourceDivCell>
              )
            },
            filterFn: 'numberFn' as FilterFnOption<MasterFundingRound>,
            sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
          },
        ),
      ),
      columnHelper.accessor('pricePerShare', {
        header: 'Issue Price',
        id: 'pricePerShare',
        cell: (cell) => {
          if (!isNotEditable(cell)) {
            return defaultCellFn(cell)
          }
          return (
            <SourceDivCell cell={cell}>
              {cell.getValue() ? numberToReadableDollarString(cell.getValue(), true) : undefined}
            </SourceDivCell>
          )
        },
        meta: {
          inputType: 'input',
          textCellFormat: DollarsCellFormat,
          updateRemote: getUpdateRemote<number>('pricePerShare'),
          // createProps: {
          //   // @ts-ignore
          //   createdObjectKey: 'pricePerShare',
          //   isRequired: false,
          // },
        },
        sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
      }),
      ...editorColumn(
        columnHelper.accessor('postMoneyValuation', {
          header: 'Post Money Valuation',
          id: 'postMoneyValuation',
          cell: (cell) => {
            if (!isNotEditable(cell)) {
              return defaultCellFn(cell)
            }
            return (
              <SourceDivCell cell={cell}>
                {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue()) : undefined}
              </SourceDivCell>
            )
          },
          meta: {
            inputType: 'input',
            textCellFormat: BigIntDollarsCellFormat,
            updateRemote: getUpdateRemote<number>('postMoneyValuation'),
            // createProps: {
            //   // @ts-ignore
            //   createdObjectKey: 'postMoneyValuation',
            //   isRequired: false,
            // },
          },
          sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
          filterFn: 'numberFn' as FilterFnOption<MasterFundingRound>,
        }),
      ),
      ...editorColumn(
        columnHelper.accessor('announcedPostMoneyValuation', {
          header: 'Announced Post Money Valuation',
          id: 'announcedPostMoneyValuation',
          cell: (cell) => {
            if (!isNotEditable(cell)) {
              return defaultCellFn(cell)
            }
            return (
              <SourceDivCell cell={cell}>
                {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue()) : undefined}
              </SourceDivCell>
            )
          },
          meta: {
            inputType: 'input',
            textCellFormat: BigIntDollarsCellFormat,
            updateRemote: getUpdateRemote<number>('announcedPostMoneyValuation'),
          },
          sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
          filterFn: 'numberFn' as FilterFnOption<MasterFundingRound>,
        }),
      ),
      ...publicColumn(
        columnHelper.accessor((row) => row.postMoneyValuation || row.announcedPostMoneyValuation, {
          header: 'Post Money Valuation',
          id: 'unifiedPostMoneyValuation',
          cell: (cell) => {
            if (!isNotEditable(cell)) {
              return defaultCellFn(cell)
            }
            return (
              <SourceDivCell cell={cell}>
                {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue()) : undefined}
              </SourceDivCell>
            )
          },
          sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
          filterFn: 'numberFn' as FilterFnOption<MasterFundingRound>,
        }),
      ),
      columnHelper.accessor('sources', {
        header: 'Sources',
        id: 'sources',
        size: 130,
        cell: (cell) => {
          if (isNotEditable(cell)) {
            return (
              <div className={cellClassName}>
                {(cell.getValue() ?? []).map((val: any) => {
                  if (isValidLink(val)) {
                    return (
                      <>
                        <PluralOutsideLink href={val} target="_blank">
                          {cleanUrl(val)}
                        </PluralOutsideLink>
                        ,&nbsp;
                      </>
                    )
                  } else {
                    return <>{val},&nbsp;</>
                  }
                })}
              </div>
            )
          } else
            return (
              <LongStringCSVEditorCell
                values={cell.getValue() ?? []}
                onChange={async (newValues) => {
                  let sourcesToAdd: string[] = []
                  let sourcesToRemove: string[] = []
                  for (const value of newValues) {
                    if ((cell.getValue() ?? []).indexOf(value) === -1) {
                      sourcesToAdd.push(value)
                    }
                  }
                  for (const value of cell.getValue() ?? []) {
                    if (newValues.indexOf(value) === -1) {
                      sourcesToRemove.push(value)
                    }
                  }
                  await patchFundingRoundMutation.mutate({
                    fundingRound: {
                      id: cell.row.original.id,
                      companyId: cell.row.original.companyId,
                      sourcesToAdd,
                      sourcesToRemove,
                      adminEmail: user?.email ?? '',
                    },
                  })
                }}
              />
            )
        },
      }),
      ...editorColumn(
        columnHelper.accessor('impliedEnterpriseValue', {
          header: 'Implied Enterprise Value',
          id: 'impliedEnterpriseValue',
          cell: (cell) => {
            if (!isNotEditable(cell)) {
              return defaultCellFn(cell)
            }
            return (
              <SourceDivCell cell={cell}>
                {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue()) : undefined}
              </SourceDivCell>
            )
          },
          meta: {
            inputType: 'input',
            textCellFormat: BigIntDollarsCellFormat,
            updateRemote: getUpdateRemote<number>('impliedEnterpriseValue'),
            // createProps: {
            //   // @ts-ignore
            //   createdObjectKey: 'impliedEnterpriseValue',
            //   isRequired: false,
            // },
          },
          sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
          filterFn: 'numberFn' as FilterFnOption<MasterFundingRound>,
        }),
      ),
      ...editorColumn(
        columnHelper.accessor('announcedImpliedEnterpriseValue', {
          header: 'Announced Implied Enterprise Value',
          id: 'announcedImpliedEnterpriseValue',
          cell: (cell) => {
            if (!isNotEditable(cell)) {
              return defaultCellFn(cell)
            }
            return (
              <SourceDivCell cell={cell}>
                {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue()) : undefined}
              </SourceDivCell>
            )
          },
          meta: {
            inputType: 'input',
            textCellFormat: BigIntDollarsCellFormat,
            updateRemote: getUpdateRemote<number>('announcedImpliedEnterpriseValue'),
            // createProps: {
            //   // @ts-ignore
            //   createdObjectKey: 'announcedImpliedEnterpriseValue',
            //   isRequired: false,
            // },
          },
          sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
          filterFn: 'numberFn' as FilterFnOption<MasterFundingRound>,
        }),
      ),
      ...editorColumn(
        columnHelper.accessor((row) => row.impliedEnterpriseValue || row.announcedImpliedEnterpriseValue, {
          header: 'Implied Enterprise Value',
          id: 'unifiedImpliedEnterpriseValue',
          cell: (cell) => {
            if (!isNotEditable(cell)) {
              return defaultCellFn(cell)
            }
            return (
              <SourceDivCell cell={cell}>
                {cell.getValue() ? numberToAbbreviatedDollarString(cell.getValue()) : undefined}
              </SourceDivCell>
            )
          },
          sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
          filterFn: 'numberFn' as FilterFnOption<MasterFundingRound>,
        }),
      ),
      columnHelper.accessor('authorizedShares', {
        header: 'Authorized Shares',
        id: 'authorizedShares',
        cell: (cell) => {
          if (!isNotEditable(cell)) {
            return defaultCellFn(cell)
          }
          return (
            <SourceDivCell cell={cell}>
              {cell.getValue() ? numberToReadableString(cell.getValue()) : undefined}
            </SourceDivCell>
          )
        },
        meta: {
          inputType: 'input',
          textCellFormat: BigIntCellFormat,
          updateRemote: getUpdateRemote<number>('authorizedShares'),
        },
        sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
        filterFn: 'numberFn' as FilterFnOption<MasterFundingRound>,
      }),
      columnHelper.accessor('outstandingShares', {
        header: 'Outstanding Shares',
        id: 'outstandingShares',
        cell: (cell) => {
          if (!isNotEditable(cell)) {
            return defaultCellFn(cell)
          }
          return (
            <SourceDivCell cell={cell}>
              {cell.getValue() ? numberToReadableString(cell.getValue()) : undefined}
            </SourceDivCell>
          )
        },
        meta: {
          inputType: 'input',
          textCellFormat: BigIntCellFormat,
          updateRemote: getUpdateRemote<number>('outstandingShares'),
        },
        sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
        filterFn: 'numberFn' as FilterFnOption<MasterFundingRound>,
      }),
      columnHelper.accessor('ownershipPercent', {
        header: 'Ownership Percent',
        id: 'ownershipPercent',
        cell: (cell) => {
          if (!isNotEditable(cell)) {
            return defaultCellFn(cell)
          }
          return <SourceDivCell cell={cell}>{cell.getValue() ? `${cell.getValue() * 100} %` : undefined}</SourceDivCell>
        },

        meta: {
          inputType: 'input',
          textCellFormat: PercentCellFormat,
          updateRemote: getUpdateRemote<number>('ownershipPercent'),
        },
        sortingFn: 'numberFn' as SortingFnOption<MasterFundingRound>,
        filterFn: 'numberFn' as FilterFnOption<MasterFundingRound>,
      }),
    ]
    return columns
  }, [
    cellClassName,
    columnHelper,
    inputs.includeCompanyName,
    companiesQuery.data,
    patchFundingRoundMutation,
    user?.email,
    inputs.bgColor,
    isEditor,
  ])
  return columnsMemo
}

function LongStringCSVEditorCell(props: { values: string[]; onChange: (newValues: string[]) => void }) {
  let [value, setValue] = useState(props.values.map((val) => val + ',').join('\n'))

  useEffect(() => {
    setValue(props.values.map((val) => val + ',').join('\n'))
  }, [props.values])
  return (
    <div>
      <Popover.Root
        onOpenChange={(open) => {
          if (!open) {
            props.onChange(
              value
                .split(',')
                .map((val) => val.trim())
                .filter((val) => val.length > 0),
            )
          }
        }}>
        <Popover.Trigger
          className="p-2"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            width: '100%',
            height: '40px',
            justifyContent: 'left',
            display: 'flex',
          }}>
          {props.values.map((val) => {
            if (isValidLink(val)) {
              return (
                <>
                  <PluralOutsideLink href={val} target="_blank" key={val}>
                    {cleanUrl(val)}
                  </PluralOutsideLink>
                  ,&nbsp;
                </>
              )
            } else {
              return <>{val},&nbsp;</>
            }
          })}
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content className="w-[450px] rounded bg-white p-2 shadow-lg" sideOffset={0} align="start">
            <textarea
              className="text-sm"
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
              rows={Math.max(props.values.length + 1, 4)}
              style={{
                outline: 'none',
                border: 'none',
                width: '100%',
                height: '100%',
                resize: 'none',
              }}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  )
}

function SourceDivCell(props: PropsWithChildren<{ cell: CellContext<MasterFundingRound, string> }>) {
  let sourceMap = props.cell.row.original.sourceMap ?? {}
  function accessSourceMapValue(
    keys: string[],
    map: {
      [key: string]: {
        createdAt: string | null
        updatedBy: string | null
        updatedSource: string | null
      }
    },
  ) {
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (map[key]) {
        return map[key].updatedSource
      }
    }
  }

  let sourceMapValue = accessSourceMapValue(
    [...(publicColumnToAccessors[props.cell.column.id] ?? []), props.cell.column.id],
    sourceMap,
  )
  let cellHasVal = !!props.cell.getValue()
  let isCellParent = (props.cell.row.original.subRows?.length ?? 0) > 0
  return (
    <TableCellWithDropdown
      open={isCellParent && !cellHasVal ? false : undefined}
      disabled={isCellParent && !cellHasVal}
      button={<div className={cellClassName}>{props.children}</div>}>
      <div className="flex h-full flex-col justify-between p-1">
        {props.children}
        {cellHasVal ? (
          <div className="mt-auto flex w-fit rounded-full bg-gray-200 p-2 text-xs font-medium">
            {sourceMapValue ?? 'unknown'}
          </div>
        ) : (
          <></>
        )}
      </div>
    </TableCellWithDropdown>
  )
}
