import { Link } from 'react-router-dom'

export default function Disclaimer({ shouldApplyMarginTop = true }: { shouldApplyMarginTop?: boolean }) {
  return (
    <>
      <div className='flex flex-row gap-6 mb-4 justify-center'>
        <Link className='underline' to="/terms">Terms of Use</Link>
        <Link className='underline' to="/privacy">Privacy Policy</Link>
      </div>
      <div className={`w-full text-sm opacity-50 ${shouldApplyMarginTop ? 'mt-72' : ''}`}>
        By using this website, you acknowledge and accept our{' '}
        <Link className='underline' to="/terms">Terms of Use</Link>,{' '}
        <Link className='underline' to="/privacy">Privacy Policy</Link> and{' '}
        <Link className='underline' to="/disclaimers">disclaimers</Link>. <br /> <br />
        The content on this website is for purely informational purposes only. It does not constitute personalized
        investment advice, nor should it be construed as financial, legal, or tax advice. Nothing on this website
        constitutes an offer to buy or sell securities or a solicitation of any offer to buy or sell securities, nor is it
        a comprehensive description of Plural or its services.
        <br /> <br /> Past performance is no guarantee of future results. Any holdings referenced on the site are for
        illustrative purposes only and are not investment recommendations. Any historical returns, expected returns, or
        probability projections are hypothetical in nature and may not reflect actual performance. *A professional
        financial advisor, attorney, and/or tax professional should be consulted regarding any specific financial, legal,
        and/or tax situation relating to Plural or its services. Additional disclaimers can be found{' '}
        <Link className='underline' to="/disclaimers">here</Link>.
      </div>
    </>
  )
}
